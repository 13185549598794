import { z } from 'zod';
/**
 * Maximum number of reviewers for a community review
 */
export const COMMUNITY_REVIEW_REVIEWS_REQUIRED = 3;
/**
 * DynamoDB prefix for the community review object
 */
export const COMMUNITY_REVIEW_DB_PREFIX = 'communityReview';
/**
 * Expiration time in milliseconds for the community review
 */
export const COMMUNITY_REVIEW_EXPIRATION_TIME_MS = 60 * 30 * 1000;
export const CommunityReviewHeartbeatTokenSchema = z.object({
    projectId: z.string(),
    reviewerId: z.string(),
    // Token creation time
    iat: z.number(),
});
export const communityReviewEndpointIds = {
    ASSIGN_COMMUNITY_REVIEW: 'ASSIGN_COMMUNITY_REVIEW',
};
