import equals from 'ramda/src/equals'
import isNil from 'ramda/src/isNil'
import createListStoreKey from 'root/src/client/logic/api/util/createListStoreKey'
import { idProp, generalApiModuleDescriptionLenses } from 'root/src/client/logic/api/lenses'
import initApiListRequest from 'root/src/client/logic/api/actions/initApiListRequest'
import apiListRequestSuccess from 'root/src/client/logic/api/actions/apiListRequestSuccess'
import apiListRequestError from 'root/src/client/logic/api/actions/apiListRequestError'
import setCurrentPage from 'root/src/client/logic/list/actions/setCurrentPage'
import setHasMore from 'root/src/client/logic/list/actions/setHasMore'
import setAllPage from 'root/src/client/logic/list/actions/setAllPage'
import setLoadingBlockList from 'root/src/client/logic/list/actions/setLoadingBlockList'
import apiRecordRequestSuccess from 'root/src/client/logic/api/actions/apiRecordRequestSuccess'
import apiRecordRequestError from 'root/src/client/logic/api/actions/apiRecordRequestError'
import apiFetchUserDataSuccess from 'root/src/client/logic/api/actions/apiFetchUserDataSuccess'
import recordTypeSelector from 'root/src/client/logic/api/selectors/recordTypeSelector'
import endpointTypeSelector from 'root/src/client/logic/api/selectors/endpointTypeSelector'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import currentRouteObj from 'root/src/client/logic/route/selectors/currentRouteObj'
import removeNilFields from 'root/src/shared/util/removeNilFields'
import clearList from 'root/src/client/logic/api/actions/clearList'
import myProfileSelector from 'root/src/client/logic/route/selectors/myProfileSelector'
import setRedirectRoute from 'root/src/client/logic/route/actions/setRedirectRoute'
import { listModuleLenses } from 'root/src/client/logic/list/lenses'

const { viewRedirectOn404 } = generalApiModuleDescriptionLenses
const { viewConstantListStoreKey, viewIsClientPaginated } = listModuleLenses

const loadingHof = async ({ setLoading, setFn, innerFn }) => {
	if (setLoading) {
		setFn(true)
		const result = await innerFn()
		setFn(false)
		return result
	}
	return innerFn()
}

// TODO cleaning up this file would be useful
export const fetchList = async ({
	dispatch, state, endpointId, payload, clearBefore, moduleId, setLoading,
	moduleDescriptions,
}) => {
	const recordType = recordTypeSelector(endpointId)
	const routeId = currentRouteObj(state)
	const constantListStoreKey = viewConstantListStoreKey(moduleId, moduleDescriptions)
	const constantListStoreKeyValue = moduleDescriptions?.[moduleId]?.constantListStoreKeyValue
	const listStoreKey = createListStoreKey(
		routeId,
		constantListStoreKeyValue ?? endpointId,
		moduleId,
		constantListStoreKey ? {} : removeNilFields(payload),
	)

	const isClientPaginated = viewIsClientPaginated(moduleId, moduleDescriptions)
	dispatch(initApiListRequest(listStoreKey))

	return loadingHof({
		setLoading,
		setFn: loading => dispatch(setLoadingBlockList(loading, moduleId)),
		innerFn: async () => {
			if (clearBefore) {
				dispatch(
					clearList({
						clearBefore,
						listKeyPropsObj: {
							moduleId,
							routeId: routeId.routeId,
							endpointId: constantListStoreKeyValue ?? endpointId,
						},
					}),
				)
			}
			const lambdaRes = await invokeApiRequest(endpointId, payload, state)
			const { statusCode, body, statusError, generalError, error } = lambdaRes
			if (equals(statusCode, 200)) {
				if (!isNil(body.allPage) || !isNil(body.totals?.total)) {
					dispatch(setAllPage(body.allPage || body.totals?.total, moduleId))
				}
				dispatch(
					apiListRequestSuccess(
						listStoreKey, recordType, body, moduleId, { isClientPaginated, clearBefore },
					),
				)

				if (payload.currentPage) {
					const allPage = body.allPage ?? body.totals?.total
					const hasMore = !(payload.currentPage >= allPage || !allPage)
					dispatch(setHasMore(hasMore, moduleId))
				}

				if (body.currentPage) {
					dispatch(setCurrentPage(body.currentPage, moduleId))
				}
			} else {
				const err = { ...statusError, ...generalError, ...error }
				dispatch(apiListRequestError(listStoreKey, err))
			}
			return lambdaRes
		},
	})
}

export const fetchRecord = async ({
	dispatch, state, endpointId, payload, moduleId, moduleDescriptions, accessToken,
}) => {
	const recordType = recordTypeSelector(endpointId)
	const recordId = idProp(payload) || payload?.recordId

	const lambdaRes = await invokeApiRequest(endpointId, payload, state, accessToken)
	const redirect = viewRedirectOn404(moduleId, moduleDescriptions)
	const { statusCode, body, statusError, generalError } = lambdaRes
	if (equals(statusCode, 200)) {
		dispatch(apiRecordRequestSuccess(recordType, body, recordId))
	} else if (equals(statusCode, 404) && redirect) {
		// add redirectRouteId in descriptions if needed
		const { routeId, routeParams } = myProfileSelector(state)
		dispatch(setRedirectRoute({ routeId, routeParams }))
	} else if (recordId) { // else creating, don't need record error state
		const error = { ...statusError, ...generalError }
		dispatch(apiRecordRequestError(recordType, recordId, error))
	}

	return lambdaRes
}

export const fetchUserData = async ({
	dispatch, state, endpointId, payload,
}) => {
	const lambdaRes = await invokeApiRequest(endpointId, payload, state)
	const recordType = recordTypeSelector(endpointId)
	if (equals(lambdaRes.statusCode, 200)) {
		dispatch(apiFetchUserDataSuccess(lambdaRes.body, recordType))
	}
	return lambdaRes
}

export const fetchOther = async ({ state, endpointId, payload }) => (
	invokeApiRequest(endpointId, payload, state)
)

const endpointTypeFunctionMap = {
	list: fetchList,
	other: fetchOther,
	record: fetchRecord,
	userData: fetchUserData,
}

const fetch = ({
	endpointId, state, payload, clearBefore, dispatch, getState, moduleId,
	moduleDescriptions, setLoading, accessToken,
}) => {
	const endpointType = endpointTypeSelector(endpointId)
	return endpointTypeFunctionMap[endpointType]({
		dispatch,
		state,
		endpointId,
		payload,
		getState,
		clearBefore,
		moduleId,
		moduleDescriptions,
		setLoading,
		accessToken,
	})
}

export default (
	endpointId, payload = {}, clearBefore, moduleId, moduleDescriptions, setLoading = true, accessToken = undefined,
	// eslint-disable-next-line consistent-return
) => async (dispatch, getState) => {
	if (!isNil(endpointId)) {
		try {
			const state = getState()
			const props = {
				state,
				payload,
				clearBefore,
				dispatch,
				getState,
				moduleId,
				moduleDescriptions,
				setLoading,
				accessToken,
			}
			if (Array.isArray(endpointId)) {
				return await Promise.all(endpointId.map(endpoint => fetch({ endpointId: endpoint, ...props })))
			}
			return await fetch({ endpointId, ...props })
		} catch (e) {
			console.error(e)
		}
	}
}
