import { dropGetEventEndpoint } from 'root/src/server/modules/drops/endpoints/dropGetEvent.endpoint';
import { uncompletedPendingDropsAlertEndpoint } from 'root/src/server/modules/drops/endpoints/uncompletedPendingDropsAlert.endpoint';
import { getDropMediaEndpoint } from 'root/src/server/modules/drops/endpoints/getDropMedia.endpoint';
import { getDropWatchTimePredictionEndpoint } from 'root/src/server/modules/drops/endpoints/getDropWatchTimePrediction.endpoint';
import { uncompletedDropDaresReminderEndpoint } from 'root/src/server/modules/drops/endpoints/uncompletedDropDaresReminder.endpoint';
import { setEmailSubscriptionDropEndpoint } from 'root/src/server/modules/drops/endpoints/setEmailSubscriptionDrop.endpoint';
import { trainDropWatchTimePredictionEndpoint } from 'root/src/server/modules/drops/endpoints/trainDropWatchTimePrediction.endpoint';
import { getUserCampaignDataEndpoint } from 'root/src/server/modules/drops/endpoints/getUserCampaignData.endpoint';
import { getDropStatisticsEndpoint } from 'root/src/server/modules/drops/endpoints/getDropStatistics.endpoint';
export const dropsEndpoints = Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, uncompletedPendingDropsAlertEndpoint), uncompletedDropDaresReminderEndpoint), dropGetEventEndpoint), getDropMediaEndpoint), getDropWatchTimePredictionEndpoint), setEmailSubscriptionDropEndpoint), getUserCampaignDataEndpoint), trainDropWatchTimePredictionEndpoint), getDropStatisticsEndpoint);
