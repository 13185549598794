export const brandEndpointIds = {
    GET_BRAND_USERS: 'GET_BRAND_ACCOUNTS',
    DELETE_BRAND_USER: 'DELETE_BRAND_USER',
    INVITE_BRAND_USER: 'INVITE_BRAND_USER',
    UPDATE_BRAND_ROLE: 'UPDATE_BRAND_ROLE',
    SET_BRAND_SETTINGS: 'SET_BRAND_SETTINGS',
    SCHEDULE_DEMO: 'SCHEDULE_DEMO',
    WIPE_ACCOUNT: 'WIPE_ACCOUNT',
    WIPE_ACCOUNT_TRIGGER: 'WIPE_ACCOUNT_TRIGGER',
};
export const brandUsersQueryKeys = {
    brandAdmins: () => ['brand', 'admins'],
    brandUsers: (filter) => ['brand', 'users', filter],
    brand: () => ['brand'],
};
export const brandUsersMutationKeys = {
    invite: () => ['brand', 'invite'],
    remove: () => ['brand', 'remove'],
    edit: () => ['brand', 'edit'],
};
export var BrandUsersQueryParam;
(function (BrandUsersQueryParam) {
    BrandUsersQueryParam["Filter"] = "filter";
})(BrandUsersQueryParam || (BrandUsersQueryParam = {}));
