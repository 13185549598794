import { authenticationTypes } from 'root/src/shared/constants/auth';
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types';
import { pointsEndpointIds } from 'root/src/shared/points/points.constants';
const payloadSchema = {
    type: 'object',
    properties: {
        dropId: { type: 'string' },
    },
    required: ['dropId'],
    additionalProperties: false,
};
export const responseSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            platform: { type: 'string' },
            platformId: { type: 'string' },
            displayName: { type: 'string' },
            brandId: { type: 'string' },
            dropId: { type: 'string' },
        },
    },
};
export const getPointsAllowlistCreators = {
    [pointsEndpointIds.GET_POINTS_ALLOWLIST_CREATORS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: AuthGroup.Admin,
        payloadSchema,
        responseSchema,
    },
};
