import { POINTS_CASH_OUT_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { MIN_POINTS_PAYOUT_AMOUNT, MIN_POINTS_PAYOUT_AMOUNT_WITHOUT_FEE } from 'root/src/shared/constants/pointsCashOut'
import { formatNumberWithCommas } from 'root/src/shared/util/formatNumberWithCommas'
import { events } from 'root/src/shared/constants/analytics'
import analyticsSendItem from 'root/src/client/logic/analytics/util/analyticsSendItem'
import userIdSelector from 'root/src/client/logic/auth/selectors/userIdSelector'
import wait from 'root/src/shared/util/wait'
import { pointsEndpointIds } from 'root/src/shared/points/points.constants'

export default {
	[POINTS_CASH_OUT_MODAL_MODULE_ID]: {
		moduleType: 'modal',
		modalTitle: 'SUBMIT YOUR POINTS',
		modalText: 'READY TO SUBMIT?',
		modalContent: {
			noticeText:
				`**The money will be deposited into your paypal account\n within 7 days of processing**
				 **If you submit more than ${formatNumberWithCommas(
		MIN_POINTS_PAYOUT_AMOUNT_WITHOUT_FEE,
	)} at once, we pay all transaction fees.\n Otherwise, you pay the fees.**`,
			buttons: [
				{
					buttonText: 'CANCEL',
					type: 'cancel',
					onClickFn: ({ displayModal }) => { //
						displayModal(undefined)
					},
				},
				{
					buttonText: 'SUBMIT',
					onClickFn: async ({
						displayModal,
						setError,
						apiRequest,
						creatorPoints,
						// eslint-disable-next-line consistent-return
					}) => {
						if (creatorPoints < MIN_POINTS_PAYOUT_AMOUNT) {
							return setError(
								`Minimal amount of points to payout equals to ${formatNumberWithCommas(
									MIN_POINTS_PAYOUT_AMOUNT,
								)}`,
							)
						}
						const { error } = await apiRequest(pointsEndpointIds.INIT_POINTS_PAYOUT, {})
						if (error) {
							return setError(error.message)
						}
						const userId = userIdSelector()
						analyticsSendItem(events.pointsCashOut, userId)
						// wait time until sqs message gets triggered
						await wait(6000)
						displayModal(undefined)
					},
				},
			],
		},
	},
}
