const errorMessage = {
	pattern: 'URL must be from Twitch, YouTube, Facebook, X (formerly Twitter), try an unshortened link.',
}

export default {
	type: 'object',
	properties: {
		videoURL: {
			anyOf: [
				{
					type: 'string',
					pattern: '^(https?:\\/\\/)?(www.)?(go.twitch.(tv|com)|clips.twitch.(tv|com)|.(tv|com)|twitch.(tv|com)((\\/.*?)?)(\\/(v|videos)|(collections)|(.*?)\\/clip))([^ ])*[^=]$',
					errorMessage,
				},
				{
					type: 'string',
					pattern: '^(https?:\\/\\/)?(www.)?(player\\.twitch\\.tv\\/\\?video=v?(\\d*))(([\\?&]t=\\w+)?)(/?)$',
					errorMessage,
				},
				{
					type: 'string',
					pattern: '^(https?:\\/\\/)?(www.|m.)?(((youtu.be\\/))([\\w-]+))(([\\?]t=\\d{1,}s?)*)(/?)$',
					errorMessage,
				},
				{
					type: 'string',
					pattern: '^(https?:\\/\\/)?(www.|m.)?(((youtube\\.com\\/watch)))(([\\?&#](v|t|.*)=[\\w-]+?)*)(/?)$',
					errorMessage,
				},
				{
					type: 'string',
					pattern: '^(https?:\\/\\/)?(www.|m.)?facebook.com\\/(.*?videos(.*?)\\/(\\d+)(.*?)|(watch\\/\\?(.*?)v=(\\d+)))(/?)$',
					errorMessage,
				},
				{
					type: 'string',
					// https://regexr.com/6p3v5
					pattern: '^(https?:\\/\\/)?(((www\\.)?tiktok\\.com\\/((t\\/\\w*\\/?)|(\\S+\\/video\\/\\d+\\S+)))|vm\\.tiktok\\.com\\/\\w+\\/?)$',
					errorMessage,
				},
				{
					type: 'string',
					pattern: '^(https?:\\/\\/)?(www.)?(twitter|x)\.com\\/(?:#!\\/)?(\\w+)\\/status(es)?\\/(\\d+)$',
					errorMessage,
				},
				// YouTube shorts/clips
				// https://regexr.com/7ctuu
				{
					type: 'string',
					pattern: '^(?:https?:\\/\\/)?(?:www\\.)?youtube\\.com\\/(?:shorts|clip)\\/([a-zA-Z0-9_-]+)(?:\\?.*)?$',
					errorMessage,
				},
			],
		},
		notesForReviewer: {
			type: 'string',
			maxLength: 500,
			errorMessage: "Text shouldn't be longer than 500 characters",
		},
	},
	required: ['videoURL'],
	additionalProperties: false,
}
