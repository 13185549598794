import { memo } from 'react';
import modalSuccessImg from 'root/src/client/public/modalSuccessImg.png';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDownloadCampaignReportFormProvider } from 'root/src/client/v2/_pages/campaign-creators/components/CampaignReportModal/hooks/useCampaignReportForm';
import { FormProvider, useController, useFormContext } from 'react-hook-form';
import { ModalLayout } from 'root/src/client/v2/common/layouts/ModalLayout';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormControl from '@mui/material/FormControl';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment-mini';
import LoadingCircles from 'root/src/client/v2/common/components/LoadingCircles';
import { LinkedCampaignForm, } from 'root/src/client/v2/_pages/campaign-creators/components/CampaignReportModal/LinkedCampaignField';
export const CampaignReportModalBase = memo((props) => {
    const { reportOptions } = props;
    const form = useDownloadCampaignReportFormProvider(reportOptions.dropEventId, {
        to: reportOptions === null || reportOptions === void 0 ? void 0 : reportOptions.to,
        from: reportOptions === null || reportOptions === void 0 ? void 0 : reportOptions.from,
        onlyEarnedPointsCreators: reportOptions === null || reportOptions === void 0 ? void 0 : reportOptions.onlyEarnedPointsCreators,
        excludeWhitelistedCreators: reportOptions === null || reportOptions === void 0 ? void 0 : reportOptions.excludeWhitelistedCreators,
    });
    return (<FormProvider {...form}>
			<h1 className="text-xl text-center font-mono font-bold">Confirmation</h1>
			<img src={modalSuccessImg.src} alt="successImage" className="w-[250px] m-auto py-2"/>
			<form className="flex flex-col gap-5" onSubmit={form.handleSubmit}>
				<div className="flex flex-col gap-4">
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DateField name="from" label="From"/>
						<DateField name="to" label="To"/>
					</LocalizationProvider>
				</div>
				<LinkedCampaignForm reportDropId={reportOptions.dropEventId}/>
				<div className="px-4">
					<ReportByGameField />
				</div>
				<Submit />
			</form>
		</FormProvider>);
});
export const CampaignReportModal = memo((props) => {
    return (<ModalLayout>
			<div className="w-[500px] flex flex-col">
				<CampaignReportModalBase {...props}/>
			</div>
		</ModalLayout>);
});
const ReportByGameField = memo(() => {
    const form = useFormContext();
    const { field, fieldState } = useController({
        name: 'isReportByGame',
        control: form.control,
    });
    return (<FormControl data-error={Boolean(fieldState.error)} error={Boolean(fieldState.error)}>
			<FormControlLabel control={(<Checkbox value={field.value} onChange={(_, checked) => field.onChange(checked)}/>)} label="Report by game?"/>
		</FormControl>);
});
const DateField = memo(({ name, label }) => {
    const form = useFormContext();
    const { field, fieldState } = useController({
        name,
        control: form.control,
    });
    return (<FormControl data-error={Boolean(fieldState.error)} error={Boolean(fieldState.error)}>
			<DateTimePicker label={label} value={(field === null || field === void 0 ? void 0 : field.value) ? moment(field.value) : null} onChange={(value) => {
            field.onChange(value ? moment(value).toISOString() : undefined);
        }}/>
		</FormControl>);
});
const Submit = memo(() => {
    const form = useFormContext();
    const { isSubmitting } = form.formState;
    return (<ButtonNew type="submit" className="font-bold flex justify-center uppercase gap-2" disabled={isSubmitting} aria-disabled={isSubmitting}>
			{isSubmitting && <div className="flex flex-initial"><LoadingCircles width={20} height={20}/></div>}
			{isSubmitting ? 'Generating report' : 'Generate report'}
		</ButtonNew>);
});
