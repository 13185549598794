import map from 'ramda/src/map'

import lensesFromSchema from 'root/src/shared/util/lensesFromSchema'

import createProject from 'root/src/shared/descriptions/endpoints/createProject'
import savePartialDareForm from 'root/src/shared/descriptions/endpoints/savePartialDareForm'
import getProject from 'root/src/shared/descriptions/endpoints/getProject'
import pledgeProject from 'root/src/shared/descriptions/endpoints/pledgeProject'
import auditProject from 'root/src/shared/descriptions/endpoints/auditProject'
import updateProject from 'root/src/shared/descriptions/endpoints/updateProject'
import addPayoutMethod from 'root/src/shared/descriptions/endpoints/addPayoutMethod'
import getPayoutMethod from 'root/src/shared/descriptions/endpoints/getPayoutMethod'
import updatePayoutMethod from 'root/src/shared/descriptions/endpoints/updatePayoutMethod'
import acceptProject from 'root/src/shared/descriptions/endpoints/acceptProject'
import rejectProject from 'root/src/shared/descriptions/endpoints/rejectProject'
import getTwitchProfiles from 'root/src/shared/descriptions/endpoints/getTwitchProfiles'
import addToken from 'root/src/shared/descriptions/endpoints/addToken'
import removeAuthToken from 'root/src/shared/descriptions/endpoints/removeAuthToken'
import verifyAccount from 'root/src/shared/descriptions/endpoints/verifyAccount'
import getPendingDeliveries from 'root/src/shared/descriptions/endpoints/getPendingDeliveries'
import auditFavorites from 'root/src/shared/descriptions/endpoints/auditFavorites'
import reviewDelivery from 'root/src/shared/descriptions/endpoints/reviewDelivery'
import processDelayedPayouts from 'root/src/shared/descriptions/endpoints/processDelayedPayouts'
import payOutstandingPayouts from 'root/src/shared/descriptions/endpoints/payOutstandingPayouts'
import getPayoutAdmin from 'root/src/shared/descriptions/endpoints/getPayoutAdmin'
import clearPartialFormKeys from 'root/src/shared/descriptions/endpoints/clearPartialFormKeys'
import { initCollectCreatorDataEndpoint } from 'root/src/server/modules/creators/endpoints/initCollectCreatorData.endpoint'
import getCreatorData from 'root/src/shared/descriptions/endpoints/getCreatorData'
import auditFollowCreator from 'root/src/shared/descriptions/endpoints/auditFollowCreator'
import getProjects from 'root/src/shared/descriptions/endpoints/getProjects'
import getTopContributors from 'root/src/shared/descriptions/endpoints/getTopContributors'
import addCoupon from 'root/src/shared/descriptions/endpoints/addCoupon'
import getCoupons from 'root/src/shared/descriptions/endpoints/getCoupons'
import updateCoupon from 'root/src/shared/descriptions/endpoints/updateCoupon'
import deleteCoupon from 'root/src/shared/descriptions/endpoints/deleteCoupon'
import getCouponByName from 'root/src/shared/descriptions/endpoints/getCouponByName'
import getUserCouponByName from 'root/src/shared/descriptions/endpoints/getUserCouponByName'
import addComment from 'root/src/shared/descriptions/endpoints/addComment'
import getComments from 'root/src/shared/descriptions/endpoints/getComments'
import deleteComment from 'root/src/shared/descriptions/endpoints/deleteComment'
import addFeaturedDare from 'root/src/shared/descriptions/endpoints/addFeaturedDare'
import deleteFeaturedDare from 'root/src/shared/descriptions/endpoints/deleteFeaturedDare'
import getDaresTwitch from 'root/src/shared/descriptions/endpoints/getDaresTwitch'
import getTwitchBannedUsers from 'root/src/shared/descriptions/endpoints/getTwitchBannedUsers'
import searchTwitchGames from 'root/src/shared/descriptions/endpoints/searchTwitchGames'
import pledgeProjectExternal from 'root/src/shared/descriptions/endpoints/pledgeProjectExternal'
import obsAudioAlert from 'root/src/shared/descriptions/endpoints/obsAudioAlert'
import getAlertsUrl from 'root/src/shared/descriptions/endpoints/getAlertsUrl'
import twitchStreamInit from 'root/src/shared/descriptions/endpoints/twitchStreamInit'
import twitchChatSendCyclicMessage from 'root/src/shared/descriptions/endpoints/twitchChatSendCyclicMessage'
import payoutProjectToCapture from 'root/src/shared/descriptions/endpoints/payoutProjectToCapture'
import getAlertsConfig from 'root/src/shared/descriptions/endpoints/getAlertsConfig'
import setAlertsConfig from 'root/src/shared/descriptions/endpoints/setAlertsConfig'
import auditCommentLikes from 'root/src/shared/descriptions/endpoints/auditCommentLikes'
import setTwitchOverlayConfig from 'root/src/shared/descriptions/endpoints/setTwitchOverlayConfig'
import getTwitchOverlayConfig from 'root/src/shared/descriptions/endpoints/getTwitchOverlayConfig'
import getProfile from 'root/src/shared/descriptions/endpoints/getProfile'
import updateProfile from 'root/src/shared/descriptions/endpoints/updateProfile'
import getUploadSignedUrl from 'root/src/shared/descriptions/endpoints/getUploadSignedUrl'
import getUserData from 'root/src/shared/descriptions/endpoints/getUserData'
import addCommentReply from 'root/src/shared/descriptions/endpoints/addCommentReply'
import getCommentReplies from 'root/src/shared/descriptions/endpoints/getCommentReplies'
import getShortTermAuthToken from 'root/src/shared/descriptions/endpoints/getShortTermAuthToken'
import getPendingBrandUsers from 'root/src/shared/descriptions/endpoints/getPendingBrandUsers'
import addCustomSound from 'root/src/shared/descriptions/endpoints/addCustomSound'
import getCustomSounds from 'root/src/shared/descriptions/endpoints/getCustomSounds'
import deleteCustomSound from 'root/src/shared/descriptions/endpoints/deleteCustomSound'
import testAlert from 'root/src/shared/descriptions/endpoints/testAlert'
import getCreators from 'root/src/shared/descriptions/endpoints/getCreators'
import getIsAlertsActive from 'root/src/shared/descriptions/endpoints/getIsAlertsActive'
import sendDareExpirationReminder from 'root/src/shared/descriptions/endpoints/sendDareExpirationReminder'
import updateCustomSoundName from 'root/src/shared/descriptions/endpoints/updateCustomSoundName'
import cleanupDbBackups from 'root/src/shared/descriptions/endpoints/cleanupDbBackups'
import setObsTopDaresConfig from 'root/src/shared/descriptions/endpoints/setObsTopDaresConfig'
import getObsTopDaresConfig from 'root/src/shared/descriptions/endpoints/getObsTopDaresConfig'
import dareExpirationActionsRunner from 'root/src/shared/descriptions/endpoints/dareExpirationActionsRunner'
import dropAddEvent from 'root/src/shared/descriptions/endpoints/dropAddEvent'
import dropEditEvent from 'root/src/shared/descriptions/endpoints/dropEditEvent'
import dropActivateEvent from 'root/src/shared/descriptions/endpoints/dropActivateEvent'
import dropSendPlatinumEmail from 'root/src/shared/descriptions/endpoints/dropSendPlatinumEmail'
import getDropEvents from 'root/src/shared/descriptions/endpoints/getDropEvents'
import adminGeneratePaymentLink from 'root/src/shared/descriptions/endpoints/adminGeneratePaymentLink'
import dropAdminAudit from 'root/src/shared/descriptions/endpoints/dropAdminAudit'
import dropLifecycleGoingLive from 'root/src/shared/descriptions/endpoints/dropLifecycleGoingLive'
import dropLifecycleGoingOff from 'root/src/shared/descriptions/endpoints/dropLifecycleGoingOff'
import dropActivationAudit from 'root/src/shared/descriptions/endpoints/dropActivationAudit'
import dropSubscriptionAudit from 'root/src/shared/descriptions/endpoints/dropSubscriptionAudit'
import checkBrandActiveCampaign from 'root/src/shared/descriptions/endpoints/checkBrandActiveCampaign'
import createProjectRewardSender from 'root/src/shared/descriptions/endpoints/sendFirstDropDareEmail'
import createProjectMessages from 'root/src/shared/descriptions/endpoints/createProjectMessages'
import getShortUrlReport from 'root/src/shared/descriptions/endpoints/getShortUrlReport'
import setEmail from 'root/src/shared/descriptions/endpoints/setEmail'
import verifyNewEmail from 'root/src/shared/descriptions/endpoints/verifyNewEmail'
import removeStaleUsers from 'root/src/shared/descriptions/endpoints/removeStaleUsers'
import collectDropEventData from 'root/src/shared/descriptions/endpoints/collectDropEventData'
import nightbotAuth from 'root/src/shared/descriptions/endpoints/nightbotAuth'
import nightbotCommandHandler from 'root/src/shared/descriptions/endpoints/nightbotCommandHandler'
import checkClipAndDeliver from 'root/src/shared/descriptions/endpoints/checkClipAndDeliver'
import getBrandCodes from 'root/src/shared/descriptions/endpoints/getBrandCodes'
import joinGiveaway from 'root/src/shared/descriptions/endpoints/joinGiveaway'
import startGiveaway from 'root/src/shared/descriptions/endpoints/startGiveaway'
import finishGiveaway from 'root/src/shared/descriptions/endpoints/finishGiveaway'
import getCreatorsWhoOnboardingStuck
	from 'root/src/shared/descriptions/endpoints/getCreatorsWhoOnboardingStuck'
import getTopActiveCreators from 'root/src/shared/descriptions/endpoints/getTopActiveCreators'
import getProjectBrand from 'root/src/shared/descriptions/endpoints/getProjectBrand'
import reviewDeliveryBrand from 'root/src/shared/descriptions/endpoints/reviewDeliveryBrand'
import getDropGameData from 'root/src/shared/descriptions/endpoints/getDropGameData'
import refreshNightbotTokens from 'root/src/shared/descriptions/endpoints/refreshNightbotTokens'
import updateCreatorSitemap from 'root/src/shared/descriptions/endpoints/updateCreatorSitemap'
import adminBanUserIp from 'root/src/shared/descriptions/endpoints/adminBanUserIp'
import adminUnbanUserIp from 'root/src/shared/descriptions/endpoints/adminUnbanUserIp'
import reportCtaClick from 'root/src/shared/descriptions/endpoints/reportCtaClick'
import collectShortUrlData from 'root/src/shared/descriptions/endpoints/collectShortUrlData'
import brokenLinksCrawler from 'root/src/shared/descriptions/endpoints/brokenLinksCrawler'
import joinGiveawayAlert from 'root/src/shared/descriptions/endpoints/joinGiveawayAlert'
import getActiveGiveaway from 'root/src/shared/descriptions/endpoints/getActiveGiveaway'
import joinGiveawayFromPanel from 'root/src/shared/descriptions/endpoints/joinGiveawayFromPanel'
import searchTwitchUsers from 'root/src/shared/descriptions/endpoints/searchTwitchUsers'
import getTwitchUserData from 'root/src/shared/descriptions/endpoints/getTwitchUserData'
import sendMonthlyInvoice from 'root/src/shared/descriptions/endpoints/sendMonthlyInvoice'
import requestDropEventInvoice from 'root/src/shared/descriptions/endpoints/requestDropEventInvoice'
import paypalDropEventPayment from 'root/src/shared/descriptions/endpoints/paypalDropEventPayment'
import setRedirectUrl from 'root/src/shared/descriptions/endpoints/setRedirectUrl'
import getPanelSettings from 'root/src/shared/descriptions/endpoints/getPanelSettings'
import setPanelSettings from 'root/src/shared/descriptions/endpoints/setPanelSettings'
import setCreatorsTiers from 'root/src/shared/descriptions/endpoints/setCreatorsTiers'
import confirmTokenUser from 'root/src/shared/descriptions/endpoints/confirmTokenUser'
import setDarePinned from 'root/src/shared/descriptions/endpoints/setDarePinned'
import sendLowKeysEmails from 'root/src/shared/descriptions/endpoints/sendLowKeysEmails'
import addToSendGridList from 'root/src/shared/descriptions/endpoints/addToSendGridList'
import modifyGamePlatforms from 'root/src/shared/descriptions/endpoints/modifyGamePlatforms'
import getDaresReport from 'root/src/shared/descriptions/endpoints/getDaresReport'
import setPreferredPlatform from 'root/src/shared/descriptions/endpoints/setPreferredPlatform'
import monitorTwitchClips from 'root/src/shared/descriptions/endpoints/monitorTwitchClips'
import twitchStreamEnd from 'root/src/shared/descriptions/endpoints/twitchStreamEnd'
import adminGetToken from 'root/src/shared/descriptions/endpoints/adminGetToken'
import changeCampaignStatus from 'root/src/shared/descriptions/endpoints/changeCampaignStatus'
import setUserNotificationSettings from 'root/src/shared/descriptions/endpoints/setUserNotificationSettings'
import getUserNotificationSettings from 'root/src/shared/descriptions/endpoints/getUserNotificationSettings'
import postSignUp from 'root/src/shared/descriptions/endpoints/postSignUp'
import preSignUpToken from 'root/src/shared/descriptions/endpoints/signUpToken'
import getCreatorPointsTransactions from 'root/src/shared/descriptions/endpoints/getCreatorPointsTransactions'
import checkUserHasGame from 'root/src/shared/descriptions/endpoints/checkUserHasGame'
import lambdaHealthCheck from 'root/src/shared/descriptions/endpoints/lambdaHealthCheck'
import lambdaHealthCheckCron from 'root/src/shared/descriptions/endpoints/lambdaHealthCheckCron'
import addTermsAcceptedCampaign from 'root/src/shared/descriptions/endpoints/addTermsAcceptedCampaign'
import getPointsMarketplaceItems from 'root/src/shared/descriptions/endpoints/getPointsMarketplaceItems'
import manageMarketplaceItem from 'root/src/shared/descriptions/endpoints/manageMarketplaceItem'
import getCampaignTransactions from 'root/src/shared/descriptions/endpoints/getCampaignTransactions'
import getCampaignStreams from 'root/src/shared/descriptions/endpoints/getCampaignStreams'
import adminCampaignCreatorsReport from 'root/src/shared/descriptions/endpoints/adminCampaignCreatorsReport'
import updateCreatorCampaignPoints from 'root/src/shared/descriptions/endpoints/updateCreatorCampaignPoints'
import authApiProxy from 'root/src/shared/descriptions/endpoints/authApiProxy'
import updateDeliveryRatio from 'root/src/shared/descriptions/endpoints/updateDeliveryRatio'
import createProjectDrop from 'root/src/shared/descriptions/endpoints/createProjectDrop'
import createProjectProduct from 'root/src/shared/descriptions/endpoints/createProjectProduct'
import getSignedUpCreators from 'root/src/shared/descriptions/endpoints/getSignedUpCreators'
import moveAccountItems from 'root/src/shared/descriptions/endpoints/moveAccountItems'
import userUpdateTwitch from 'root/src/shared/descriptions/endpoints/userUpdateTwitch'
import updateCampaignDealId from 'root/src/shared/descriptions/endpoints/updateCampaignDealId'
import checkForNotifications from 'root/src/shared/descriptions/endpoints/checkForNotifications'
import { rewardEndpoints } from 'root/src/server/modules/rewards/endpoints/reward.endpoints'
import addWhitelistedCreators from 'root/src/shared/descriptions/endpoints/addWhitelistedCreators'
import getWhitelistedCreators from 'root/src/shared/descriptions/endpoints/getWhitelistedCreators'
import removeWhitelistedCreator from 'root/src/shared/descriptions/endpoints/removeWhitelistedCreator'
import getTransactions from 'root/src/shared/descriptions/endpoints/getTransactions'
import createTransactionPaidOffPlatform from 'root/src/shared/descriptions/endpoints/createTransactionPaidOffPlatform'
import monitorPaypalBalancePointsPayout from 'root/src/shared/descriptions/endpoints/monitorPaypalBalancePointsPayout'
import getPointsStatistics from 'root/src/shared/descriptions/endpoints/getPointsStatistics'
import monitorGitHubPullRequests
	from 'root/src/shared/descriptions/endpoints/monitorGitHubPullRequests'
import githubWebhookCallback from 'root/src/shared/descriptions/endpoints/githubWebhookCallback'
import removeDare from 'root/src/shared/descriptions/endpoints/removeDare'
import getStreamsStatsReportPayload from 'root/src/shared/descriptions/endpoints/getStreamsStatsReportPayload'
import deleteDropEvent from 'root/src/shared/descriptions/endpoints/deleteDropEvent'
import getSelfProfile from 'root/src/shared/descriptions/endpoints/getSelfProfile'
import adminGetBannedAccounts from 'root/src/shared/descriptions/endpoints/adminGetBannedAccounts'
import adminUnbanAccount from 'root/src/shared/descriptions/endpoints/adminUnbanAccount'
import collectCampaignsChartsData
	from 'root/src/shared/descriptions/endpoints/collectCampaignsChartsData'
import getDashboardCreatorDares from 'root/src/shared/descriptions/endpoints/getDashboardCreatorDares'
import getPreviousDares from 'root/src/shared/descriptions/endpoints/getPreviousDares'
import initSignedUpCreatorsReport from 'root/src/shared/descriptions/endpoints/initSignedUpCreatorsReport'
import generateSignedUpCreatorsReport from 'root/src/shared/descriptions/endpoints/generateSignedUpCreatorsReport'
import getPrivateDropCreatorsReportPayload
	from 'root/src/shared/descriptions/endpoints/getPrivateDropCreatorsReportPayload'
import getCreatorCampaignPoints from 'root/src/shared/descriptions/endpoints/getCreatorCampaignPoints'
import resetCampaignDares from 'root/src/shared/descriptions/endpoints//resetCampaignDares'
import { deliverEndpoint } from 'root/src/server/modules/dares/endpoints/deliver.endpoint'
import { getUserDropEventLandingShortUrl } from 'root/src/shared/descriptions/endpoints/getUserDropEventLandingShortUrl'
import { verifyEndpoints } from 'root/src/server/modules/creators/verify/verifyEndpoints'
import { snapshotEndpoints } from 'root/src/shared/snapshots/endpoints/snapshot.endpoints'
import { setBrandSettingsEndpoint } from 'root/src/server/modules/brand/endpoints/setBrandSettings.endpoint'
import { getDropShortLinksMappingReport } from 'root/src/shared/descriptions/endpoints/getDropShortLinksMappingReport'
import { giveawayEndpoints } from 'root/src/server/modules/giveaways/endpoints/giveaway.endpoints'
import { autoApproveDeliveries } from 'root/src/shared/descriptions/endpoints/autoApproveDeliveries'
import { usersEndpoints } from 'root/src/server/modules/users/endpoints/users.endpoints'
import { pointsEndpoints } from 'root/src/server/modules/points/endpoints/points.endpoints'
import { creatorApiEndpoints } from 'root/src/shared/creatorApi/endpoints/creatorApi.endpoints'
import { agencyEndpoints } from 'root/src/server/modules/agency/endpoints/agency.endpoints'
import { brandEndpoints } from 'root/src/server/modules/brand/endpoints/brand.endpoints'
import { updateDropMnemonicId } from 'root/src/shared/descriptions/endpoints/updateDropMnemonicId'
import { deleteProfileBackground } from 'root/src/shared/descriptions/endpoints/deleteProfileBackground'
import { searchTwitchGamesReporting } from 'root/src/shared/descriptions/endpoints/searchTwitchGamesReporting'
import { publisherBanListEndpoints } from 'root/src/server/modules/publisherLists/endpoints/publisherBanList.endpoints'
import { demoDashboardEndpoints } from 'root/src/server/modules/demoDashboard/endpoints/demoDashboard.endpoints'
import { dropsEndpoints } from 'root/src/server/modules/drops/endpoints/drops.endpoints'
import { steamEndpoints } from 'root/src/server/modules/steam/endpoints/steam.endpoints'
import { collectCreatorDataEndpoint } from 'root/src/server/modules/creators/endpoints/collectCreatorData.endpoint'
import { dashboardEndpoints } from 'root/src/server/modules/dashboard/endpoints/dashboard.endpoints'
import { generateDropReportEndpoint } from 'root/src/server/modules/dropReports/endpoints/generateDropReport.endpoint'
import { mailsEndpoints } from 'root/src/server/modules/mails/endpoints/mails.endpoints'
import { surveyEndpoints } from 'root/src/server/modules/survey/endpoints/survey.endpoints'
import { streamsEndpoints } from 'root/src/server/modules/streams/endpoints/streams.endpoints'
import { projectsEndpoints } from 'root/src/server/modules/project/endpoints/projects.endpoints'
import { shortUrlEndpoints } from 'root/src/server/modules/shortUrl/endpoints/shortUrl.endpoints'
import { communityReviewEndpoints } from 'root/src/server/modules/communityReview/endpoints/communityReview.endpoints'
import reviewDeliveryInternal from 'root/src/shared/descriptions/endpoints/reviewDeliveryInternal'
import { gamesEndpoints } from 'root/src/server/modules/games/endpoints/games.endpoints'

const allEndpoints = {
	...acceptProject,
	...addPayoutMethod,
	...auditFavorites,
	...auditProject,
	...clearPartialFormKeys,
	...createProject,
	...deliverEndpoint,
	...getTwitchProfiles,
	...getPayoutAdmin,
	...getPayoutMethod,
	...getPendingDeliveries,
	...getProject,
	...processDelayedPayouts,
	...payOutstandingPayouts,
	...pledgeProject,
	...rejectProject,
	...reviewDelivery,
	...reviewDeliveryInternal,
	...removeAuthToken,
	...savePartialDareForm,
	...updatePayoutMethod,
	...updateProject,
	...verifyAccount,
	...initCollectCreatorDataEndpoint,
	...collectCreatorDataEndpoint,
	...getCreatorData,
	...getProjects,
	...getTopContributors,
	...getTopActiveCreators,
	...auditFollowCreator,
	...addCoupon,
	...getCoupons,
	...getCouponByName,
	...getUserCouponByName,
	...updateCoupon,
	...deleteCoupon,
	...addComment,
	...getComments,
	...deleteComment,
	...addFeaturedDare,
	...deleteFeaturedDare,
	...getDaresTwitch,
	...getTwitchBannedUsers,
	...pledgeProjectExternal,
	...obsAudioAlert,
	...getAlertsUrl,
	...twitchStreamInit,
	...twitchChatSendCyclicMessage,
	...payoutProjectToCapture,
	...getProfile,
	...updateProfile,
	...getUploadSignedUrl,
	...getAlertsConfig,
	...setAlertsConfig,
	...auditCommentLikes,
	...getUserData,
	...addCommentReply,
	...getCommentReplies,
	...getShortTermAuthToken,
	...addToken,
	...setBrandSettingsEndpoint,
	...getPendingBrandUsers,
	...addCustomSound,
	...getCustomSounds,
	...deleteCustomSound,
	...testAlert,
	...getCreators,
	...getIsAlertsActive,
	...sendDareExpirationReminder,
	...updateCustomSoundName,
	...cleanupDbBackups,
	...setTwitchOverlayConfig,
	...getTwitchOverlayConfig,
	...setObsTopDaresConfig,
	...getObsTopDaresConfig,
	...dareExpirationActionsRunner,
	...dropAddEvent,
	...dropEditEvent,
	...dropActivateEvent,
	...dropSendPlatinumEmail,
	...getDropEvents,
	...adminGeneratePaymentLink,
	...dropAdminAudit,
	...dropLifecycleGoingLive,
	...dropLifecycleGoingOff,
	...dropActivationAudit,
	...dropSubscriptionAudit,
	...getShortUrlReport,
	...checkBrandActiveCampaign,
	...createProjectRewardSender,
	...setEmail,
	...verifyNewEmail,
	...createProjectMessages,
	...removeStaleUsers,
	...collectDropEventData,
	...nightbotAuth,
	...nightbotCommandHandler,
	...checkClipAndDeliver,
	...joinGiveaway,
	...startGiveaway,
	...finishGiveaway,
	...searchTwitchGames,
	...searchTwitchGamesReporting,
	...getBrandCodes,
	...getCreatorsWhoOnboardingStuck,
	...getProjectBrand,
	...reviewDeliveryBrand,
	...getDropGameData,
	...refreshNightbotTokens,
	...updateCreatorSitemap,
	...reportCtaClick,
	...collectShortUrlData,
	...brokenLinksCrawler,
	...joinGiveawayAlert,
	...getActiveGiveaway,
	...searchTwitchUsers,
	...getTwitchUserData,
	...requestDropEventInvoice,
	...sendMonthlyInvoice,
	...joinGiveawayFromPanel,
	...adminBanUserIp,
	...adminUnbanUserIp,
	...paypalDropEventPayment,
	...setRedirectUrl,
	...getPanelSettings,
	...setPanelSettings,
	...setCreatorsTiers,
	...confirmTokenUser,
	...setDarePinned,
	...sendLowKeysEmails,
	...addToSendGridList,
	...modifyGamePlatforms,
	...getDaresReport,
	...setPreferredPlatform,
	...monitorTwitchClips,
	...twitchStreamEnd,
	...adminGetToken,
	...changeCampaignStatus,
	...setUserNotificationSettings,
	...getUserNotificationSettings,
	...postSignUp,
	...preSignUpToken,
	...getCreatorPointsTransactions,
	...checkUserHasGame,
	...lambdaHealthCheck,
	...lambdaHealthCheckCron,
	...authApiProxy,
	...updateDeliveryRatio,
	...createProjectDrop,
	...createProjectProduct,
	...getSignedUpCreators,
	...moveAccountItems,
	...userUpdateTwitch,
	...addTermsAcceptedCampaign,
	...getPointsMarketplaceItems,
	...manageMarketplaceItem,
	...getCampaignTransactions,
	...getCampaignStreams,
	...adminCampaignCreatorsReport,
	...updateCreatorCampaignPoints,
	...updateCampaignDealId,
	...checkForNotifications,
	...addWhitelistedCreators,
	...getWhitelistedCreators,
	...removeWhitelistedCreator,
	...getTransactions,
	...createTransactionPaidOffPlatform,
	...monitorPaypalBalancePointsPayout,
	...getPointsStatistics,
	...monitorGitHubPullRequests,
	...githubWebhookCallback,
	...rewardEndpoints,
	...removeDare,
	...getStreamsStatsReportPayload,
	...deleteDropEvent,
	...getSelfProfile,
	...adminGetBannedAccounts,
	...adminUnbanAccount,
	...collectCampaignsChartsData,
	...getDashboardCreatorDares,
	...getPreviousDares,
	...initSignedUpCreatorsReport,
	...generateSignedUpCreatorsReport,
	...getPrivateDropCreatorsReportPayload,
	...getCreatorCampaignPoints,
	...getUserDropEventLandingShortUrl,
	...resetCampaignDares,
	...verifyEndpoints,
	...snapshotEndpoints,
	...getDropShortLinksMappingReport,
	...giveawayEndpoints,
	...autoApproveDeliveries,
	...usersEndpoints,
	...pointsEndpoints,
	...creatorApiEndpoints,
	...agencyEndpoints,
	...brandEndpoints,
	...updateDropMnemonicId,
	...deleteProfileBackground,
	...publisherBanListEndpoints,
	...demoDashboardEndpoints,
	...dropsEndpoints,
	...steamEndpoints,
	...dashboardEndpoints,
	...generateDropReportEndpoint,
	...mailsEndpoints,
	...surveyEndpoints,
	...streamsEndpoints,
	...projectsEndpoints,
	...shortUrlEndpoints,
	...communityReviewEndpoints,
	...gamesEndpoints,
}
export default map(
	endpoint => ({
		payloadLenses: lensesFromSchema(endpoint.payloadSchema),
		responseLenses: lensesFromSchema(endpoint.responseSchema),
		...endpoint,
	}),
	allEndpoints,
)
