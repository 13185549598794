import { dropEndpointIds } from 'root/src/shared/drops/drops.constants';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
export const getDropStatisticsEndpoint = {
    [dropEndpointIds.GET_DROP_STATISTICS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: [userGroups.brand, userGroups.admin],
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
            },
            required: ['dropId'],
        },
    },
};
