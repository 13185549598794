import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import { DEMO_DASHBOARD_RECORD_ID } from 'root/src/shared/demoDashboard/demoDashboard.constants';
function useRecordIdSelector(state) {
    var _a, _b;
    return (_b = (_a = state === null || state === void 0 ? void 0 : state.route) === null || _a === void 0 ? void 0 : _a.routeParams) === null || _b === void 0 ? void 0 : _b.recordId;
}
export function useIsDemoDashboard() {
    const recordId = useAppSelector(useRecordIdSelector);
    return recordId === DEMO_DASHBOARD_RECORD_ID;
}
