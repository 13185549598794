import { gamesEndpointIds } from 'root/src/shared/games/games.constants';
import { authenticationTypes } from 'root/src/shared/constants/auth';
export const getGamesStatsEndpoint = {
    [gamesEndpointIds.GET_GAMES_STATS]: {
        authentication: authenticationTypes.optional,
        payloadSchema: {
            type: 'object',
            properties: {
                gameIds: {
                    type: 'array',
                    items: { type: 'string' },
                    minItems: 1,
                    maxItems: 10,
                },
                excludedGameIds: { type: 'array', items: { type: 'string' } },
                languages: { type: 'array', items: { type: 'string' } },
                timeRange: {
                    type: 'number', enum: [7, 14, 30, 60, 90, 180, 365],
                },
                minCcv: { type: 'number' },
                maxCcv: { type: 'number' },
                minStreamTimePercentage: { type: 'number' },
                maxStreamTimePercentage: { type: 'number' },
                hasTiktok: { type: 'boolean' },
            },
        },
        required: ['timeRange', 'gameIds'],
    },
};
