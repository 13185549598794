import { createContext, useState, useContext, useMemo } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment-mini';
import { ButtonNew } from 'root/src/client/v2/common/components/form/ButtonNew';
import { ErrorField } from 'root/src/client/v2/_pages/add-campaign/components/AddCampaignForm/ErrorField';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDropEventsList } from 'root/src/client/v2/brandDashboard/hooks/useDropEventsList';
import CancelIcon from '@mui/icons-material/Cancel';
export const LinkedCampaignContext = createContext(null);
export const LinkedCampaignForm = ({ reportDropId }) => {
    return (<LinkedCampaignProvider>
			<LinkedCampaignField reportDropId={reportDropId}/>
		</LinkedCampaignProvider>);
};
const LinkedCampaignProvider = ({ children, }) => {
    const value = useLinkedCampaignProvider();
    return (<LinkedCampaignContext.Provider value={value}>
			{children}
		</LinkedCampaignContext.Provider>);
};
const useLinkedCampaignProvider = () => {
    const [linkedDrop, setLinkedDrop] = useState(null);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    return {
        linkedDrop,
        from,
        to,
        setLinkedDrop,
        setFrom,
        setTo,
    };
};
const useLinkedCampaignContext = () => {
    const context = useContext(LinkedCampaignContext);
    if (!context) {
        throw new Error('useLinkedCampaignContext should be use within LinkedCampaignProvider');
    }
    return context;
};
const useLinkedCampaignField = () => {
    var _a, _b;
    const { linkedDrop, from, to, setLinkedDrop, setFrom, setTo } = useLinkedCampaignContext();
    const form = useFormContext();
    const { fields, append } = useFieldArray({
        name: 'linkedDrops',
        control: form.control,
    });
    const onLinkedDrop = (_, value) => {
        setLinkedDrop(value);
        setFrom((value === null || value === void 0 ? void 0 : value.liveDate) || null);
        setTo((value === null || value === void 0 ? void 0 : value.expirationDate) || null);
    };
    const onSubmit = () => {
        const linkedDrops = [...fields.values()];
        if (linkedDrops.length >= 5) {
            form.setError('linkedDrops', { message: 'Max count of linked drops is 5!' });
            return;
        }
        if (!linkedDrop) {
            return;
        }
        const newLinkedDrop = {
            id: linkedDrop.id,
            title: linkedDrop.dropTitle || linkedDrop.campaignName,
            from,
            to,
        };
        append(newLinkedDrop);
        setLinkedDrop(null);
        setFrom(null);
        setTo(null);
    };
    return {
        linkedDrop,
        from,
        to,
        onLinkedDrop,
        setFrom,
        setTo,
        onSubmit,
        errorMessage: (_b = (_a = form.formState.errors) === null || _a === void 0 ? void 0 : _a.linkedDrops) === null || _b === void 0 ? void 0 : _b.message,
    };
};
const LinkedCampaignField = ({ reportDropId }) => {
    const { linkedDrop, onSubmit, errorMessage } = useLinkedCampaignField();
    return (<div className="flex flex-col gap-3">
			<h3 className="font-medium text-center text-xl">Link another Campaign</h3>
			<div className="mb-2 h-[1px] w-full bg-dark-5"/>
			<div className="flex flex-col gap-4">
				<AutocompleteCampaignField reportDropId={reportDropId}/>
				<TimeRangesField />
			</div>
			<ButtonNew type="button" onClick={onSubmit} className="font-bold flex justify-center uppercase" disabled={!linkedDrop}>
				Add campaign
			</ButtonNew>
			<ErrorField error={errorMessage}/>
			<div className="my-1 h-[1px] w-full bg-dark-5"/>
			<LinkedCampaignsList />
		</div>);
};
const TimeRangesField = () => {
    const { from, to, setFrom, setTo } = useLinkedCampaignField();
    return (<LocalizationProvider dateAdapter={AdapterMoment}>
			<DateTimePicker label="From" value={from ? moment(from) : null} onChange={(value) => {
            setFrom(value ? moment(value).toISOString() : null);
        }}/>
			<DateTimePicker label="To" value={to ? moment(to) : null} onChange={(value) => {
            setTo(value ? moment(value).toISOString() : null);
        }}/>
		</LocalizationProvider>);
};
const AutocompleteCampaignField = ({ reportDropId }) => {
    const { options } = useAutocompleteCampaignField(reportDropId);
    const { linkedDrop, onLinkedDrop } = useLinkedCampaignField();
    return (<Autocomplete id="linkedDrop" value={linkedDrop} onChange={onLinkedDrop} options={options} getOptionLabel={option => option.campaignName} isOptionEqualToValue={(option, value) => option.id === value.id} renderOption={(props, option) => {
            return (<li {...props} key={option.id}>
						{option.campaignName}
					</li>);
        }} renderInput={(props) => {
            return (<TextField label="Choose Campaign (optional)" placeholder="Campaign Name" {...props}/>);
        }}/>);
};
const useAutocompleteCampaignField = (reportDropId) => {
    const form = useFormContext();
    const options = useDropEventsList();
    const linkedDrops = form.getValues('linkedDrops');
    const filteredOptions = useMemo(() => {
        return options.filter((option) => {
            const isAlreadyAdded = linkedDrops.find(drop => drop.id === option.id);
            return !isAlreadyAdded && (option.id !== reportDropId);
        });
    }, [linkedDrops]);
    return { options: filteredOptions };
};
const LinkedCampaignsList = () => {
    const form = useFormContext();
    const { linkedDrops } = useWatch({ control: form.control });
    const onDelete = (dropId) => {
        const filteredLinkedDrops = form.getValues('linkedDrops').filter(drop => drop.id !== dropId);
        form.setValue('linkedDrops', filteredLinkedDrops);
        form.setError('linkedDrops', {});
    };
    return (<div className="flex gap-2 w-full flex-wrap justify-center">
			{linkedDrops === null || linkedDrops === void 0 ? void 0 : linkedDrops.map((drop) => {
            return (<DropBadge dropId={drop.id} key={drop.id} label={drop.title} onDelete={onDelete}/>);
        })}
		</div>);
};
const DropBadge = ({ dropId, label, onDelete }) => {
    return (<div className="px-2 rounded-3xl min-w-[0%] max-w-[100%] cursor-pointer border-red-600 border-[1px]" style={{ borderColor: 'var(--dark-1)' }} title={label}>
			<div className="flex items-center w-full justify-center">
				<p className="truncate text-sm max-w-[180px] ml-1 mr-1 px-1 py-[6px]">{label}</p>
				<CancelIcon fontSize="small" className="hover:brightness-75" onClick={() => onDelete(dropId)}/>
			</div>
		</div>);
};
