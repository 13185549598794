export const pointsEndpointIds = {
    AWARD_POINTS: 'AWARD_POINTS',
    ADD_PAID_OFF_PLATFORM_POINTS: 'ADD_PAID_OFF_PLATFORM_POINTS',
    INIT_POINTS_PAYOUT: 'INIT_POINTS_PAYOUT',
    CREATE_POINTS_APPLICATION: 'CREATE_POINTS_APPLICATION',
    GET_POINTS_APPLICATIONS: 'GET_POINTS_APPLICATIONS',
    SET_POINTS_APPLICATION_STATE: 'SET_POINTS_APPLICATION_STATE',
    UPDATE_CAMPAIGN_TERMS_ACCEPTED: 'UPDATE_CAMPAIGN_TERMS_ACCEPTED',
    GET_CAMPAIGN_TERMS_ACCEPTED: 'GET_CAMPAIGN_TERMS_ACCEPTED',
    LIST_CAMPAIGN_TERMS_ACCEPTED: 'LIST_CAMPAIGN_TERMS_ACCEPTED',
    CHECK_EXPIRED_POINTS_RESERVATIONS: 'CHECK_EXPIRED_POINTS_RESERVATIONS',
    GET_POINTS_ALLOWLIST_CREATORS: 'GET_POINTS_ALLOWLIST_CREATORS',
    ADD_POINTS_ALLOWLIST_CREATORS: 'ADD_POINTS_ALLOWLIST_CREATORS',
};
