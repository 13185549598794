import { useAppSelector } from 'root/src/client/logic/helpers/useStore';
import { useIsDemoDashboard } from 'root/src/client/logic/route/hooks/useIsDemoDashboard';
import { dropEventsDemoList } from 'root/src/shared/demoDashboard/demoDashboard.mocks';
export function useDropEventsSelector(state) {
    var _a, _b;
    return (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.userData) === null || _b === void 0 ? void 0 : _b.dropEvents;
}
export function useDropEvents() {
    var _a;
    const dropEvents = (_a = useAppSelector(useDropEventsSelector)) !== null && _a !== void 0 ? _a : [];
    const isDemo = useIsDemoDashboard();
    return isDemo ? dropEventsDemoList : dropEvents;
}
