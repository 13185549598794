import { DEMO_DASHBOARD_RECORD_ID } from 'root/src/shared/demoDashboard/demoDashboard.constants';
import { dropEventStatusEnum } from 'root/src/shared/constants/dropEvent';
import { Language } from 'root/src/shared/@types/languageCodes';
export const payloadFiltersMock = {
    from: '2023-08-01T00:00Z',
    to: '2023-09-01T00:00Z',
};
export const totalChartsData = {
    creatorsCount: 411,
    daresCompletedCount: 503,
    followerCount: 4200000,
    streamsPostsCount: 633,
    tiktokViews: 1400000,
    viewMinutes: 211000,
    youtubeViews: 765000,
};
export const chartsDataSeeds = {
    creatorsCount: [
        0, 6, 51, 57, 61, 63, 82, 95, 107, 119, 130, 134, 135,
        136, 161, 167, 188, 263, 316, 328, 337, 344, 345, 349,
        358, 371, 374, 378, 385, 397, 404, 411,
    ],
    daresCompletedCount: [
        0, 18, 27, 44, 51, 52, 54, 56, 58, 75, 94, 109, 131,
        139, 150, 155, 189, 190, 191, 261, 316, 323, 360, 370,
        402, 430, 456, 464, 472, 480, 488, 503,
    ],
    followerCount: [
        0, 573266, 609256, 763642, 772891, 796245, 1176974, 1309313, 1318471,
        1378300, 1430096, 1668165, 1828910, 2047780, 2074953, 2177127, 2294870,
        2332963, 2513851, 2562300, 2622320, 2887006, 3027338, 3052071, 3303621,
        3422971, 3555182, 3600140, 3702657, 3955391, 4052553, 4200000,
    ],
    streamsPostsCount: [
        0, 39, 85, 102, 115, 148, 148, 149, 171, 180, 222,
        229, 233, 233, 300, 342, 386, 387, 396, 440, 454, 455,
        505, 533, 536, 560, 586, 609, 612, 617, 625, 633,
    ],
    tiktokViews: [
        0, 40497, 70640, 174006, 180155, 201022, 218687, 225263,
        265906, 428736, 466046, 531260, 542392, 567655, 621149, 676502,
        684724, 741301, 775345, 801788, 806211, 910527, 975066, 981854,
        1059690, 1177172, 1213492, 1273759, 1296114, 1326706, 1333096, 1400000,
    ],
    viewMinutes: [
        0, 3581, 29119, 32168, 44093, 56473, 61214, 68728,
        76480, 76502, 78335, 80220, 85494, 108711, 117321, 117453,
        132944, 136836, 139398, 139719, 144843, 148264, 156991, 175137,
        176637, 182358, 182671, 183462, 199416, 206053, 210889, 211000,
    ],
    youtubeViews: [
        0, 75892, 81839, 89344, 105629, 108129, 135458, 159777,
        191657, 223153, 225581, 228486, 230527, 230838, 293263,
        295783, 399395, 412230, 463859, 503750, 509717, 527497,
        543906, 562322, 597433, 603840, 651615, 712329, 729094,
        730779, 744831, 765000,
    ],
};
export const videoDataMock = [
    {
        videoURL: 'https://www.tiktok.com/@lordmastermatrix/video/7322655147564322053?lang=es',
        thumbnail: 'daredrop.com/files/2dfd9ea06ac7d19a78717cbdd789a339.jpg',
        projectTitle: 'Full Circle',
        deliveredAt: '2023-08-02T09:30:00Z',
        isFeatured: true,
    },
    {
        videoURL: 'https://www.youtube.com/watch?v=aFNqwuvW1Mg&ab_channel=COL8ROCKS',
        thumbnail: 'https://i.ytimg.com/vi/aFNqwuvW1Mg/mqdefault.jpg',
        projectTitle: 'Unarmored',
        deliveredAt: '2023-08-03T19:30:00Z',
        isFeatured: true,
    },
    {
        videoURL: 'https://www.twitch.tv/russ_money/clip/HelpfulFancyMartenCharlietheUnicorn-SKGYJycv2z1wuiKl',
        thumbnail: 'https://clips-media-assets2.twitch.tv/I4JC_kihmCcnL9s9cLoipQ/AT-cm%7CI4JC_kihmCcnL9s9cLoipQ-preview-480x272.jpg',
        projectTitle: 'Martial Law',
        deliveredAt: '2023-08-05T05:00:00Z',
        isFeatured: true,
    },
    {
        videoURL: 'https://clips.twitch.tv/CleverTallLocustTBTacoLeft--27KsxXLD9pmG9M1',
        thumbnail: 'https://clips-media-assets2.twitch.tv/WwuKA_tuyrMVyw_aZhvTNQ/AT-cm%7CWwuKA_tuyrMVyw_aZhvTNQ-preview-480x272.jpg',
        projectTitle: 'Dirty Harry',
        deliveredAt: '2023-08-06T15:00:00Z',
        isFeatured: true,
    },
    {
        videoURL: 'https://www.twitch.tv/russ_money/clip/SeductiveSmokyTomatoPanicVis-RoTSMEtfyo-fG6nu',
        thumbnail: 'https://clips-media-assets2.twitch.tv/d5NYsUaE0jBLX8qPnxup7g/50109709437-offset-2338-preview-480x272.jpg',
        projectTitle: 'Kill It With Fire',
        deliveredAt: '2023-08-08T01:00:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/CrazySpikyManateeJKanStyle-3Ns7W4c5zcEXMpAv',
        thumbnail: 'https://clips-media-assets2.twitch.tv/-enoxtzbO1uHOc3BFzKQLw/42188052681-offset-33972-preview-480x272.jpg',
        projectTitle: 'Jack in the WHAT?!',
        deliveredAt: '2023-08-09T10:30:00Z',
    },
    {
        videoURL: 'https://www.twitch.tv/russ_money/clip/GenerousColdbloodedAntelopePipeHype-13eUzQFiJEHIf8N6',
        thumbnail: 'https://clips-media-assets2.twitch.tv/PxhN_td3lM0BYiRByH_Deg/AT-cm%7CPxhN_td3lM0BYiRByH_Deg-preview-480x272.jpg',
        projectTitle: 'Survivor',
        deliveredAt: '2023-08-10T20:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/FaithfulAdorableHorseKippa-9SggBOCN2R2lXTto',
        thumbnail: 'https://clips-media-assets2.twitch.tv/MWN6dvUoIATgjdkdpV07aA/AT-cm%7CMWN6dvUoIATgjdkdpV07aA-preview-480x272.jpg',
        projectTitle: 'Ding Ding',
        deliveredAt: '2023-08-12T06:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/SpeedyDiligentCheetahTBTacoLeft-Ejt4casWpzoIAHIc',
        thumbnail: 'https://clips-media-assets2.twitch.tv/gkZnYxI7eHGrFqc6CAByHA/42518773662-offset-7306-preview-480x272.jpg',
        projectTitle: 'Ding Ding',
        deliveredAt: '2023-08-13T16:00:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/SingleEnthusiasticPorcupineKippa-j0X1nY627r82lA2e',
        thumbnail: 'https://clips-media-assets2.twitch.tv/yqpG9I9F9Z2Otu6_NpQNng/AT-cm%7CyqpG9I9F9Z2Otu6_NpQNng-preview-480x272.jpg',
        projectTitle: 'Ding Ding',
        deliveredAt: '2023-08-15T02:00:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/SuspiciousWildLardDendiFace-Zm6HMN8Kw4VVqQfw',
        thumbnail: 'https://clips-media-assets2.twitch.tv/AlarCGZuhzDfCsOFVakH8A/41650474856-offset-4980-preview-480x272.jpg',
        projectTitle: 'Daddy Warbucks',
        deliveredAt: '2023-08-16T11:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/SpeedyVibrantHeronEagleEye-qIwX7AEPKbRTgeZz',
        thumbnail: 'https://clips-media-assets2.twitch.tv/ar3MfqiMF-wBDym0U4Hmcw/AT-cm%7Car3MfqiMF-wBDym0U4Hmcw-preview-480x272.jpg',
        projectTitle: 'Survivalist',
        deliveredAt: '2023-08-17T21:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/ArtsySpotlessTireTheRinger-Ltu6t2xPzzM38DHN',
        thumbnail: 'https://clips-media-assets2.twitch.tv/YP9gDi7burdsT88zN_AxzQ/AT-cm%7CYP9gDi7burdsT88zN_AxzQ-preview-480x272.jpg',
        projectTitle: 'Kill It With Fire',
        deliveredAt: '2023-08-19T07:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/OddEnergeticPigWutFace-ff-I3njSw1iE9kAZ',
        thumbnail: 'https://clips-media-assets2.twitch.tv/TG8LAn3_iN540sNYhPZAGQ/AT-cm%7CTG8LAn3_iN540sNYhPZAGQ-preview-480x272.jpg',
        projectTitle: 'Clean Up Aisle 3',
        deliveredAt: '2023-08-20T17:00:00Z',
    },
    {
        videoURL: 'https://www.twitch.tv/videos/2024244604?filter=highlights&sort=time',
        thumbnail: 'https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/2103d8a6b3e785729fe2_skys_kawaii_74903734622_8957697254//thumb/thumb2024244604-480x272.jpg',
        projectTitle: 'Full Circle',
        deliveredAt: '2023-08-22T03:00:00Z',
    },
    {
        videoURL: 'https://www.twitch.tv/videos/2032637173',
        thumbnail: 'https://static-cdn.jtvnw.net/cf_vods/d1m7jfoe9zdc1j/12a4723c1e94c0b834d5_dudelings_99349161605_9399313572//thumb/thumb2032637173-480x272.jpg',
        projectTitle: 'Great Asset',
        deliveredAt: '2023-08-23T13:00:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/AcceptableCovertTitanSSSsss-4B1Tk-zVESCvHryL',
        thumbnail: 'https://clips-media-assets2.twitch.tv/l-CaB-AdU5Ps1REgrx68iw/AT-cm%7Cl-CaB-AdU5Ps1REgrx68iw-preview-480x272.jpg',
        projectTitle: 'Lego House',
        deliveredAt: '2023-08-24T22:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/TameStormyRaisinANELE-VC34NpbN7CQoso9P',
        thumbnail: 'https://clips-media-assets2.twitch.tv/IIwrmlQt-DaWzewkKLKPTw/AT-cm%7CIIwrmlQt-DaWzewkKLKPTw-preview-480x272.jpg',
        projectTitle: 'Get Bricked!',
        deliveredAt: '2023-08-26T08:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/ProductiveWildFlyTriHard-640cC5hFqR6jE7rA',
        thumbnail: 'https://clips-media-assets2.twitch.tv/UAWqQ-uNHAuYRakeoxO6wA/AT-cm%7CUAWqQ-uNHAuYRakeoxO6wA-preview-480x272.jpg',
        projectTitle: 'Costly Gamble',
        deliveredAt: '2023-08-27T18:30:00Z',
    },
    {
        videoURL: 'https://clips.twitch.tv/SlipperyOpenPastaFrankerZ-uCbv_lW4MlIs-x7R',
        thumbnail: 'https://clips-media-assets2.twitch.tv/r2XPSLgm7_5VuDJSLPse0Q/AT-cm%7Cr2XPSLgm7_5VuDJSLPse0Q-preview-480x272.jpg',
        projectTitle: 'Survivor',
        deliveredAt: '2023-08-29T04:00:00Z',
    },
];
export const creatorsDataMock = [
    {
        displayName: 'CrisyColo',
        mnemonicId: 'crisycolo',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/7e10243d-a284-4092-a866-850a62b2a04f-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-07T19:30:00Z',
        ],
        language: Language.English,
        seed: 5,
    },
    {
        displayName: 'Hean',
        mnemonicId: 'hean',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/63af8aae-1b48-406e-baa2-eadffc35d609-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-12T03:30:00Z',
        ],
        language: Language.English,
        seed: 8,
    },
    {
        displayName: 'ReAnimateHer | Horror Creator',
        mnemonicId: 'reanimateher',
        image: 'https://daredrop.com/files/b2e066c6729eddd41630c32cf3b56709.jpg',
        activityTimestamps: [
            '2023-08-14T15:00:00Z',
        ],
        seed: 23,
    },
    {
        displayName: 'Talmo',
        mnemonicId: 'talm0fn',
        image: 'https://daredrop.com/files/f19f5d774e763a8d2057fbea7ef84c6b.jpg',
        activityTimestamps: [
            '2023-08-02T20:30:00Z',
        ],
        seed: 41,
    },
    {
        displayName: 'imTacoCat',
        mnemonicId: 'imtacocat',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fef74fd8-1702-42d3-87ae-9986dec9e67b-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-23T22:30:00Z',
        ],
        seed: 55,
    },
    {
        displayName: 'Suxymuxy',
        mnemonicId: '_suxen_',
        image: 'https://daredrop.com/files/0c74f9a3645b5eef1ad0426b9a39ba8e.jpg',
        activityTimestamps: [
            '2023-08-19T14:00:00Z',
        ],
        seed: 96,
    },
    {
        displayName: 'Wolsk',
        mnemonicId: 'wolsk',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/068a836b-481c-4b9d-89c4-55c9b2fb812b-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-09T01:00:00Z',
        ],
        seed: 125,
    },
    {
        displayName: 'naomynha',
        mnemonicId: 'naomynha',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/d6b5c061-f68e-4373-8c64-996759f82857-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-28T21:30:00Z',
        ],
        seed: 125,
    },
    {
        displayName: 'iagomenta',
        mnemonicId: 'iagomenta',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/b949332d-3117-4f89-9b29-1fe8bafa97e5-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-22T01:30:00Z',
        ],
        seed: 128,
    },
    {
        displayName: 'tsukemono',
        mnemonicId: 'tsukemono',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/632ffb16-fcad-42e6-91fd-9c9c9abdbd23-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-21T11:00:00Z',
        ],
        seed: 131,
        language: Language.Danish,
    },
    {
        displayName: 'rehhdart',
        mnemonicId: 'rehhdart',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/144f24ee-757b-4583-8ec5-52e1111f5eec-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-14T00:00:00Z',
        ],
        seed: 217,
        language: Language.German,
    },
    {
        displayName: 'Rhariane',
        mnemonicId: 'rhariane',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/ed2fa9cb-1db3-448b-bd39-0301158fc942-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-20T20:00:00Z',
        ],
        seed: 265,
    },
    {
        displayName: 'Zetapin1',
        mnemonicId: 'zetapin1',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/5e47232c-7403-458c-8a49-a46a50cb22b4-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-08T10:30:00Z',
        ],
        seed: 266,
        language: Language.Italian,
    },
    {
        displayName: 'GamingsBlackJesus',
        mnemonicId: 'gamingsblackjesus',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/508abd35-326d-4860-a55a-b4d29a3acb9a-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-07T04:30:00Z',
        ],
        seed: 299,
    },
    {
        displayName: 'MEENOR',
        mnemonicId: 'meenorttk',
        image: 'https://daredrop.com/files/2d4ec412d41e23fe0fc277f391bce854.jpg',
        activityTimestamps: [
            '2023-08-05T23:00:00Z',
        ],
        seed: 302,
    },
    {
        displayName: 'Profe_Akimbox',
        mnemonicId: 'profe_akimbox',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/b6367ba9-9458-44de-9a95-680f970e98e7-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-03T11:30:00Z',
        ],
        seed: 308,
    },
    {
        displayName: 'Russ_Money',
        mnemonicId: 'russ_money',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/4ab0169a-7dee-4c4a-add0-e9062285b01d-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-02T05:30:00Z',
        ],
        seed: 332,
    },
    {
        displayName: 'MilkyBearTV',
        mnemonicId: 'milkybeartv',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/645f14f0-5eec-4d60-804c-039039d47f05-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-31T09:00:00Z',
        ],
        seed: 351,
    },
    {
        displayName: 'Swizzel_',
        mnemonicId: 'swizzel_',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/bd4d1c1f-73bf-4d93-acbb-7f89cc3f9898-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-16T12:00:00Z',
        ],
        seed: 381,
        language: Language.French,
    },
    {
        displayName: 'rhinosnax',
        mnemonicId: 'rhinosnax',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/e365dd40-6df2-40d9-903b-2d171b4030c2-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-31T09:30:00Z',
        ],
        seed: 381,
    },
    {
        displayName: 'Amtec41',
        mnemonicId: 'amtec41',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/amtec41-profile_image-0dc463aa05812673-300x300.jpeg',
        activityTimestamps: [
            '2023-08-30T03:00:00Z',
        ],
        seed: 401,
    },
    {
        displayName: 'StefReq',
        mnemonicId: 'stefreq',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/ef6c4e87-aa86-4f60-9fdc-d869185a4268-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-15T06:00:00Z',
        ],
        seed: 466,
    },
    {
        displayName: 'GASOFF.KNR',
        mnemonicId: 'gasoff.knr',
        image: 'https://daredrop.com/files/334672ccfd8b4dd0009b386cb5949a68.jpg',
        activityTimestamps: [
            '2023-08-28T06:30:00Z',
        ],
        seed: 482,
    },
    {
        displayName: 'John Toney',
        mnemonicId: 'johntoneyofficalpage',
        image: 'https://daredrop.com/files/576bbe33a73de984a729032eeff3ffc3.jpg',
        activityTimestamps: [
            '2023-08-04T17:00:00Z',
        ],
        seed: 495,
    },
    {
        displayName: 'vini_sirera',
        mnemonicId: 'vini_sirera',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/c718fb31-f169-4336-96c6-1c9f9dc19c6f-profile_image-300x300.jpeg',
        activityTimestamps: [
            '2023-08-17T17:30:00Z',
        ],
        seed: 498,
    },
    {
        displayName: 'E1YZE',
        mnemonicId: 'e1yze',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/6f117336-72cd-4961-874d-177bb4364283-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-24T13:00:00Z',
        ],
        seed: 507,
    },
    {
        displayName: 'Xanicfps',
        mnemonicId: 'xanicfps',
        image: 'https://daredrop.com/files/962cd60d10375d14334ae408d6bf7586.jpg',
        activityTimestamps: [
            '2023-08-04T02:00:00Z',
        ],
        seed: 510,
    },
    {
        displayName: 'Baltodermo',
        mnemonicId: 'baltodermo',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/298d8583-1e80-410b-af3b-1ad85f4747aa-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-25T04:00:00Z',
        ],
        seed: 510,
    },
    {
        displayName: 'Clairessa',
        mnemonicId: 'clairessa',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/c90d8f8e-91a3-45e5-9236-c6d1905c8e3d-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-15T21:00:00Z',
        ],
        seed: 520,
    },
    {
        displayName: 'Heruss_',
        mnemonicId: 'heruss_',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/2670d559-bdeb-4f71-b0aa-500ab317853c-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-27T00:30:00Z',
        ],
        language: Language.Japanese,
        seed: 533,
    },
    {
        displayName: 'Etain',
        mnemonicId: 'etain',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/e313cddc-17ce-4955-97dd-f131520427e0-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-05T08:00:00Z',
        ],
        seed: 545,
    },
    {
        displayName: 'RualStorge',
        mnemonicId: 'rualstorge',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/6634f85a-9905-4398-81c3-54ce33a3e7b3-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-17T02:30:00Z',
        ],
        seed: 575,
    },
    {
        displayName: 'Flintgold',
        mnemonicId: 'flintgold',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/f108b4b4-3bee-4ca1-8ca5-84f6a5eff9eb-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-18T08:30:00Z',
        ],
        seed: 588,
    },
    {
        displayName: 'DerNeoRaiden',
        mnemonicId: 'derneoraiden',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/c5ff94d6-645c-4d46-9e80-0dbcb324f725-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-18T23:30:00Z',
        ],
        seed: 622,
    },
    {
        displayName: 'whip_krew',
        mnemonicId: 'whip_krew',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/be6bd647-7122-4d36-981f-9ea24c16f29a-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-30T18:00:00Z',
        ],
        seed: 632,
    },
    {
        displayName: 'Paschoalin',
        mnemonicId: 'paschoalin',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/7e600695-95ac-4330-959b-fc0a7798a98b-profile_image-300x300.jpeg',
        activityTimestamps: [
            '2023-08-22T16:30:00Z',
        ],
        seed: 634,
    },
    {
        displayName: 'CyberWiking',
        mnemonicId: 'cyberwiking',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/768a0bb0-a38a-4e81-a59e-710470b03192-profile_image-300x300.jpeg',
        activityTimestamps: [
            '2023-08-25T19:00:00Z',
        ],
        seed: 641,
    },
    {
        displayName: 'thekatthouse',
        mnemonicId: 'thekatthouse',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/27ba8e11-5ad0-4bb1-8ca4-c007003b5b5f-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-29T12:00:00Z',
        ],
        seed: 681,
    },
    {
        displayName: 'ca_Millia',
        mnemonicId: 'ca_millia',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/b14e4a18-3f93-4284-a60d-57524381b248-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-10T22:00:00Z',
        ],
        seed: 693,
    },
    {
        displayName: 'PrincessYukiRose',
        mnemonicId: 'princessyukirose',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/b1b572a5-024a-4dd8-8927-618efb290198-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-23T07:30:00Z',
        ],
        seed: 705,
    },
    {
        displayName: 'The_Hug_Dealer',
        mnemonicId: 'the_hug_dealer',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/f575f2b1-11db-43ff-8ba1-4d949ec33f55-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-27T15:30:00Z',
        ],
        seed: 706,
    },
    {
        displayName: 'ReAnimateHer',
        mnemonicId: 'reanimateher',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/ae8fc2f7-9f24-449a-85d0-6594a44e6849-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-13T09:30:00Z',
        ],
        seed: 729,
    },
    {
        displayName: 'annypichu',
        mnemonicId: 'annypichu',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/3e49f263-2d52-41eb-b594-2f0d83c2b0f6-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-11T12:30:00Z',
        ],
        seed: 754,
    },
    {
        displayName: 'SkyS_Kawaii',
        mnemonicId: 'skys_kawaii',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/2ec25b3d-f193-487f-b52f-e835005250c1-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-26T10:00:00Z',
        ],
        seed: 780,
    },
    {
        displayName: 'DreamlikeMelody',
        mnemonicId: 'dreamlikemelody',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/3bbdc222-f27b-4fdd-b700-c14eff37d33f-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-10T07:00:00Z',
        ],
        seed: 787,
    },
    {
        displayName: 'MollyBerry',
        mnemonicId: 'mollyberry',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/8fce5b84-362d-486d-a5a6-dd011e600c6a-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-09T16:00:00Z',
        ],
        seed: 818,
    },
    {
        displayName: 'MrMamut',
        mnemonicId: 'mrmamut',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/5b9aa48f-80ec-41ce-8b75-a4c41e1c6533-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-06T13:30:00Z',
        ],
        seed: 826,
    },
    {
        displayName: 'FafrotskyVT',
        mnemonicId: 'fafrotskyvt',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/9dc36c18-fc7f-48a2-b3ac-b0e815dde7d8-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-12T18:30:00Z',
        ],
        seed: 927,
    },
    {
        displayName: 'SBuddypoke',
        mnemonicId: 'sbuddypoke',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fb314605-7008-4250-a616-5c72594e29f6-profile_image-300x300.jpg',
        activityTimestamps: [
            '2023-08-20T05:00:00Z',
        ],
        seed: 1200,
    },
    {
        displayName: 'Santi',
        mnemonicId: 'santi',
        image: 'https://static-cdn.jtvnw.net/jtv_user_pictures/fa7d018e-aa3a-4f4f-8157-d143018ebb3c-profile_image-300x300.png',
        activityTimestamps: [
            '2023-08-01T14:30:00Z',
        ],
        seed: 1500,
    },
];
export const dropEventMock = {
    expirationDate: payloadFiltersMock.to,
    dares: [],
    budget: {
        type: 'CAMPAIGN',
        amount: 500,
    },
    dropTitle: 'Demo Drop',
    liveDate: payloadFiltersMock.from,
    status: 'EXPIRED',
    campaignThumbnail: {
        fileHash: '1cc1843416d354722574bb6cdd872a4d',
        url: 'daredrop.com/files/8200ff5e-953e-4d46-b20e-7df1062ea4ac.jpeg',
        fileName: 'cropped-image.jpeg',
    },
    isUserActivated: true,
    url: {
        value: 'daredrop.com',
    },
    awardPoints: false,
    shortUrl: 'dared.io/LZaB',
    campaignProduct: 'Demo Drop',
    approved: true,
    subscriptionStatus: 'EXPIRED',
    pointsCalculationMethod: 'default',
    liveDateFormatted: 'Jan 05 2024, 19:00',
    isCommunityDrop: true,
    isMultiGame: true,
    similarGames: [
        {
            image: 'https://static-cdn.jtvnw.net/ttv-boxart/2085980140_IGDB-52x72.jpg',
            label: 'Lethal Company',
            id: 2085980140,
            value: 2085980140,
        },
    ],
    id: '2c250f5e-8b96-4338-9690-39b5045facc3',
    isPrivate: false,
    mnemonicId: DEMO_DASHBOARD_RECORD_ID,
    created: payloadFiltersMock.from,
    gameDescriptionBody: 'Welcome to our super silly, but sometimes serious, series of survival situations!\n\n- Lethal Company\n- Apex Legends\n- Fortnite\n- The Finals\n- Project Zomboid\n- Valheim\n- Minecraft\n- 7 Days to Die\n\n---\n\nCompleted Dares will earn you an entry into each of the following giveaways...\n\nEASY: $10 Steam gift card\nMEDIUM: $25 Steam gift card\nHARD: $100 Steam gift card\n\nThis community drop features NO CCV REQUIREMENT and UNLIMITED DARES! Game keys are not provided for community drops, you must own the game to participate.',
    disableUserHasGame: true,
    cta: 'Visit Dare Drop',
    dealId: 'idNotFound',
    assets: [
        {
            mobile: 'daredrop.com/files/05a502f4f9d423c2793ecac04f8d5a34.jpeg',
            fileHash: '2e6afc32d66b960a486728ef42fb4236',
            fileName: 'justsurvivejan-hand1.jpg',
            desktop: 'daredrop.com/files/7eda62b3cffbbfeca80c668edc90b399.jpeg',
        },
        {
            type: 'youtubeURL',
            url: 'https://www.youtube.com/watch?v=Nv17Rl4ZuI8',
        },
    ],
    youtubeURL: 'https://www.youtube.com/watch?v=Nv17Rl4ZuI8',
    campaignName: 'Demo Drop',
    pointsAwarded: 0,
    ccvBreakpoints: [],
    isLive: false,
    genres: [],
    followersBreakpoints: [],
    totalPrizes: 0,
    isEmailSubscribed: false,
    pointsReserved: 0,
    isAbleToParticipate: true,
    availablePrizes: {
        availableKeys: 0,
        availableCodes: 9828,
    },
    brand: {
        brandName: 'Dare Drop (Community Drop)',
        brandWebsite: 'daredrop.com',
        brandLogo: {
            small: 'daredrop.com/files/17ba2d19a81f1789a6dda0d200fc437c.jpeg',
            big: 'daredrop.com/files/3438257bd02e7b5c19f24ff35fbd2383.jpeg',
        },
        id: 'user-48023566-d902-4ea8-94b3-02c1bc5ef6af',
    },
};
export const dropEventsDemoList = [
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 1', isLive: true, subscriptionStatus: dropEventStatusEnum.ACTIVE, liveDate: '2024-04-17T23:00:00Z', mnemonicId: 'demo1' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 2', isLive: true, subscriptionStatus: dropEventStatusEnum.ACTIVE, liveDate: '2024-02-12T23:00:00Z', mnemonicId: 'demo2' }),
    Object.assign(Object.assign({}, dropEventMock), { isLive: true, subscriptionStatus: dropEventStatusEnum.ACTIVE, dropTitle: 'Demo Campaign 3', liveDate: '2023-09-15T23:00:00Z', mnemonicId: 'demo3' }),
    Object.assign(Object.assign({}, dropEventMock), { isLive: true, subscriptionStatus: dropEventStatusEnum.ACTIVE, dropTitle: 'Demo Campaign 4', liveDate: '2023-07-10T16:00:00Z', mnemonicId: 'demo4' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 5', liveDate: '2024-02-17T15:00:00Z', mnemonicId: 'demo5' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 6', liveDate: '2024-01-10T12:00:00Z', mnemonicId: 'demo6' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 7', liveDate: '2023-03-10T20:00:00Z', mnemonicId: 'demo7' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 8', liveDate: '2022-12-10T17:00:00Z', mnemonicId: 'demo8' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 9', liveDate: '2022-11-21T23:00:00Z', mnemonicId: 'demo9' }),
    Object.assign(Object.assign({}, dropEventMock), { dropTitle: 'Demo Campaign 10', liveDate: '2022-09-27T23:00:00Z', mnemonicId: 'demo10' }),
];
