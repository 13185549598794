import { getComingSoonEventsKey, getCommunityDropEventsKey, getPrivateLiveEventsKey, getSponsoredDropEventsKey, liveEventsKey, } from 'root/src/shared/constants/keys';
export const DROPS_STATUS_TAB_ID = 'status';
export const DROPS_FILTER_TAB_ID = 'filter';
export var DropsTabStatus;
(function (DropsTabStatus) {
    DropsTabStatus["Live"] = "live";
    DropsTabStatus["Soon"] = "soon";
    DropsTabStatus["Private"] = "private";
})(DropsTabStatus || (DropsTabStatus = {}));
export var DropsFilter;
(function (DropsFilter) {
    DropsFilter["All"] = "all";
    DropsFilter["Community"] = "community";
    DropsFilter["Sponsored"] = "sponsored";
})(DropsFilter || (DropsFilter = {}));
export const DROPS_INITIAL_PAGE = 1;
export const DROPS_PER_PAGE = 10;
export const DROPS_DEFAULT_TAB = DropsTabStatus.Live;
export const DROPS_DEFAULT_FILTER = DropsFilter.All;
export const DROPS_STALE_TIME_MS = 1000 * 10;
export const DROPS_TAB_SECTION_MAP = {
    [DropsTabStatus.Live]: liveEventsKey,
    [DropsTabStatus.Soon]: getComingSoonEventsKey,
    [DropsTabStatus.Private]: getPrivateLiveEventsKey,
};
export const DROPS_FILTER_SECTION_MAP = {
    [DropsFilter.All]: undefined,
    [DropsFilter.Community]: getCommunityDropEventsKey,
    [DropsFilter.Sponsored]: getSponsoredDropEventsKey,
};
export const DROPS_TAB_LIST = [
    {
        id: DROPS_STATUS_TAB_ID,
        title: 'Live',
        value: DropsTabStatus.Live,
    },
    {
        id: DROPS_STATUS_TAB_ID,
        title: 'Coming soon',
        value: DropsTabStatus.Soon,
    },
    {
        id: DROPS_STATUS_TAB_ID,
        title: 'Private',
        value: DropsTabStatus.Private,
    },
];
export const DROPS_FILTERS_LIST = [
    {
        id: DROPS_FILTER_TAB_ID,
        title: 'All',
        value: DropsFilter.All,
    },
    {
        id: DROPS_FILTER_TAB_ID,
        title: 'Community',
        value: DropsFilter.Community,
    },
    {
        id: DROPS_FILTER_TAB_ID,
        title: 'Sponsored',
        value: DropsFilter.Sponsored,
    },
];
export const DROPS_QUERY_KEY = {
    drops: 'drops',
};
export var DropQueryHash;
(function (DropQueryHash) {
    DropQueryHash["Dares"] = "drop-all-dares";
})(DropQueryHash || (DropQueryHash = {}));
