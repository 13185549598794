export const MIN_POINTS_PAYOUT_AMOUNT = 25000
export const MIN_POINTS_PAYOUT_AMOUNT_WITHOUT_FEE = 50000

// https://www.paypal.com/us/business/paypal-business-fees#statement-10
export const TRANSACTION_PAYPAL_FEE = 1 - (1 / 1.02)

export const usdRatio = 0.001

export const payoutCurrencyRatioMap = {
	USD: usdRatio,
}
