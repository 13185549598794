import { SEARCH_TWITCH_GAMES_REPORTING } from 'root/src/shared/descriptions/endpoints/endpointIds';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { searchTwitchAdminResponseSchema } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/searchTwitchAdminResponseSchema';
import { searchTwitchAdminPayloadSchema } from 'root/src/shared/descriptions/endpoints/schemas/requestSchemas/searchTwitchAdminPayloadSchema';
export const searchTwitchGamesReporting = {
    [SEARCH_TWITCH_GAMES_REPORTING]: {
        authentication: authenticationTypes.unauthenticated,
        endpointType: otherEndpointType,
        payloadSchema: searchTwitchAdminPayloadSchema,
        responseSchema: searchTwitchAdminResponseSchema,
    },
};
