import { CREATORS_PAGE_SECTIONS_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { SIGN_UP_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import {
	brandProductTypesEnum,
	dropDareDifficultyLevelEnum,
} from 'root/src/shared/constants/dropEvent'
import Creators2Blob from 'root/src/client/public/backgroundBlobs/for-creators-2.svg'
import { ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'
import path from 'ramda/src/path'
import ddLogo from 'root/src/client/public/favicons/android-chrome-192x192.jpg'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[CREATORS_PAGE_SECTIONS_MODULE_ID]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{
				position: 'right',
				image: Creators2Blob,
				style: {
					transform: 'translate(0, -23%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -10%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, 0%)',
					},
				},
			},
		],
		pageContent: [
			{
				heading: 'For the casual gamer',
				subHeading: 'and the most daring',
				text: 'Dares and challenges vary in difficulty, check them out and accept as many as you dare to do. ',
				align: 'right',
			},
			{
				heading: 'Good luck',
				subHeading: 'Have Fun',
				text: 'You’ll get a game key and attempt the dare with your fans cheering you on. The more you do, the more you win-for them. ',
				align: 'right',
				projectCard: {
					brand: {
						brandName: 'Dare Drop',
						brandLogo: {
							small: ddLogo.src,
						},
					},
					dare: {
						capacity: {
							available: 23,
							used: 17,
						},
						title: 'Can’t Touch this',
						description: 'Beat a boss without taking damage',
						games: [{ image: 'https://static-cdn.jtvnw.net/ttv-boxart/505963_IGDB-280x320.jpg' }],
						level: dropDareDifficultyLevelEnum.hard,
						product: { name: '1 Necromunda: Hired Gun Steam key for viewers', type: brandProductTypesEnum.dropKey },
					},
					dumb: true,
				},
			},
			{
				capacity: {
					available: 23,
					used: 17,
				},
				heading: 'Keys, swag, prizes',
				subHeading: 'Gimmie the loot',
				text: 'Do the dare? Awesome. We’ll verify your clip, and send you the rewards within 24 hours. ',
				align: 'right',
				buttons: [
					{
						label: 'Claim Your First Dare',
						routeId: (state) => {
							const isAuthenticated = path(['app', 'authentication'], state)
							return isAuthenticated ? routeUrls.drops() : SIGN_UP_ROUTE_ID
						},
						icon: faChevronRight,
					},
				],
			},
		],
	},
}
