export var SocialPlatform;
(function (SocialPlatform) {
    SocialPlatform["Steam"] = "steam";
})(SocialPlatform || (SocialPlatform = {}));
export var AuthGroup;
(function (AuthGroup) {
    AuthGroup["User"] = "user";
    AuthGroup["Admin"] = "admin";
    AuthGroup["Creator"] = "creator";
    AuthGroup["Viewer"] = "viewer";
    AuthGroup["Brand"] = "brand";
    AuthGroup["BrandAdmin"] = "brandAdmin";
    AuthGroup["BrandUser"] = "brandUser";
    AuthGroup["BrandEnterprise"] = "enterprise";
    AuthGroup["SuperAdmin"] = "superAdmin";
    AuthGroup["Agency"] = "agency";
    AuthGroup["AgencyAccountManager"] = "agencyAccountManager";
    AuthGroup["BetaTester"] = "betaTester";
    AuthGroup["ClipReviewer"] = "clipReviewer";
})(AuthGroup || (AuthGroup = {}));
export var GetUsersFilter;
(function (GetUsersFilter) {
    GetUsersFilter["Email"] = "email";
    GetUsersFilter["UserId"] = "userId";
    GetUsersFilter["ParentUserId"] = "parentUserId";
    GetUsersFilter["Group"] = "group";
})(GetUsersFilter || (GetUsersFilter = {}));
export var ConnectedAccountPlatform;
(function (ConnectedAccountPlatform) {
    ConnectedAccountPlatform["YouTube"] = "youtube";
    ConnectedAccountPlatform["Google"] = "google";
})(ConnectedAccountPlatform || (ConnectedAccountPlatform = {}));
