import {
	LOGIN_ROUTE_ID,
	ACCOUNT_SETTINGS_ROUTE_ID,
	PENDING_DELIVERIES_ROUTE_ID,
	PAYOUT_ADMIN_LIST_ROUTE_ID,
	FEATURED_DARES_ADMIN_ROUTE_ID,
	COUPON_ADMIN_ROUTE_ID,
	PENDING_BRANDS_USERS_ADMIN_ROUTE_ID,
	DROP_EVENTS_ADMIN_PANEL_ROUTE_ID,
	CREATOR_KEYS_ROUTE_ID,
	SHORT_URL_REPORT_ROUTE_ID,
	CAMPAIGNS_PAGE_ROUTE_ID,
	BRANDS_REPORTS_ROUTE_ID,
	BRAND_DARES_ROUTE_ID,
	BRAND_PENDING_DELIVERIES_ROUTE_ID,
	ADMIN_BAN_IP_FORM_ROUTE_ID,
	CAMPAIGNS_DASHBOARD_ROUTE_ID, DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'
import {
	CREATORS_AUTOCOMPLETE_MISC_MODULE_ID,
	AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID,
	LANGUAGE_SWITCHER_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

import userAccountDisplayNameSelector from 'root/src/client/logic/auth/selectors/userAccountDisplayNameSelector'
import isMobileResSelector from 'root/src/client/logic/app/selectors/isMobileResSelector'
import isAuthenticated from 'root/src/client/logic/auth/selectors/isAuthenticated'
import getCurrentModuleId from 'root/src/client/logic/route/util/getCurrentModuleId'
import moduleDescriptions from 'root/src/shared/descriptions/modules'
import prop from 'ramda/src/prop'
import reject from 'ramda/src/reject'
import isNil from 'ramda/src/isNil'
import getLogoutUrl from 'root/src/client/util/getLogoutUrl'
import userRoleSelector from 'root/src/client/logic/auth/selectors/userRoleSelector'
import { userGroups } from 'root/src/shared/constants/auth'
import hasDropEventSelector from 'root/src/client/logic/app/selectors/hasDropEventSelector'
import myProfileSelector from 'root/src/client/logic/route/selectors/myProfileSelector'
import agencyPublishersSelector from 'root/src/client/logic/misc/agencyPublishersSelect/selectors/agencyPublishersSelector'
import orEmpty from 'root/src/shared/util/orEmpty'
import imageSelector from 'root/src/client/logic/auth/selectors/imageSelector'
import latestCampaignMnemonicIdSelector	from 'root/src/client/logic/route/selectors/latestCampaignMnemonicIdSelector'
import { profileRouteMap } from 'root/src/shared/constants/route'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'
import { buildCookie } from 'root/src/client/v2/browser/helpers/cookies'
import { store } from 'root/src/client/logic/web'
import authDetermined from 'root/src/client/logic/app/actions/authDetermined'
import { AuthGroup } from 'root/src/server/util/awsClients/authApiClient.types'
import { PendingReviewsOverlay } from 'root/src/client/v2/_pages/review-clips/components/PendingReviewsOverlay'

export default (state, props) => {
	const moduleId = getCurrentModuleId()
	const { altNavigation } = moduleId ? moduleDescriptions[moduleId] : {}
	if (altNavigation) {
		return {
			altNavigation: true,
			...altNavigation,
		}
	}

	// all roles
	const roles = state?.app?.authentication?.groups ?? []

	// priority role
	const userRole = userRoleSelector(state)
	const isCreator = roles.includes(AuthGroup.Creator)
	const isClipReviewer = roles.includes(AuthGroup.ClipReviewer)

	const additionalLinks = [
		...(isClipReviewer ? [
			{
				label: 'Review Clips',
				routeId: routeUrls.reviewClips(),
				isNew: true,
				overlay: PendingReviewsOverlay,
			},
		] : []),
	]

	const latestCampaignMnemonicId = latestCampaignMnemonicIdSelector(state)

	const brandEnterpriseNavigation = roles.includes(userGroups.brandEnterprise) ? [
		{ label: 'Ambassador Program', href: routeUrls.ambassadorProgram() },
	] : []

	const brandCommonNavigation = [
		...orEmpty(
			agencyPublishersSelector(state).length,
			[{ moduleId: AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID }],
		),
		{ label: 'Campaigns', routeId: hasDropEventSelector(state) ? CAMPAIGNS_PAGE_ROUTE_ID : routeUrls.addCampaign() },
		...(latestCampaignMnemonicId
			? [
				{ label: 'Dashboard', routeId: CAMPAIGNS_DASHBOARD_ROUTE_ID },
			]
			: []),
		{ label: 'Brand users', href: routeUrls.brandUsers() },
		...(brandEnterpriseNavigation),
	]
	// TODO *drop* fill these todos
	// TODO resolve TODO from src/client/web/base/NavigationLinks.js:28 after adding missing routes
	const linksMap = {
		[userGroups.user]: [
			{ label: 'Drops', href: routeUrls.drops() },
			...additionalLinks,
		],
		[userGroups.creator]: [
			{ label: 'Resources', href: '/r/resources', target: '_blank' },
			{ label: 'Drops', href: routeUrls.drops() },
			...additionalLinks,
		],
		[userGroups.viewer]: [
			{ moduleId: CREATORS_AUTOCOMPLETE_MISC_MODULE_ID },
			{ label: 'Drops', href: routeUrls.drops() },
			...additionalLinks,
		],
		[userGroups.admin]: [
			{ label: 'Campaigns', routeId: hasDropEventSelector(state) ? CAMPAIGNS_PAGE_ROUTE_ID : routeUrls.addCampaign() },
			{ label: 'Drops', href: routeUrls.drops() },
			{ label: 'Dashboard', routeId: CAMPAIGNS_DASHBOARD_ROUTE_ID },
			...additionalLinks,
		],
		[userGroups.brand]: brandCommonNavigation,
		[userGroups.brandAdmin]: brandCommonNavigation,
		[userGroups.brandUser]: brandCommonNavigation,
		[userGroups.agency]: [
			...orEmpty(
				agencyPublishersSelector(state).length,
				[{ moduleId: AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID }],
			),
			...(roles?.includes(userGroups.brand)
				? [{ label: 'Campaigns', routeId: hasDropEventSelector(state) ? CAMPAIGNS_PAGE_ROUTE_ID : routeUrls.addCampaign() }]
				: []),
		],
		[userGroups.agencyAccountManager]: [
			...orEmpty(
				agencyPublishersSelector(state).length,
				[{ moduleId: AGENCY_PUBLISHERS_SELECT_MISC_MODULE_ID }],
			),
		],
	}

	const userProfile = myProfileSelector(state)
	const menuItemsMap = {
		commonTop: [
			...(isCreator && Object.values(profileRouteMap).includes(userProfile.routeId)
				? [
					{
						label: 'My Profile',
						...userProfile,
					},
					{
						label: 'My Keys',
						routeId: CREATOR_KEYS_ROUTE_ID,
					},
					{
						label: 'My Dashboard',
						href: routeUrls.creatorDashboard(),
					},
				]
				: []),
		],
		commonBottom: [
			{ label: 'Account Settings', routeId: ACCOUNT_SETTINGS_ROUTE_ID },
			{
				label: 'Sign Out',
				onClick: () => {
					const logOutCookies = [
						buildCookie('email', '', -1),
						buildCookie('refreshToken', '', -1),
						buildCookie('accessToken', '', -1),
						buildCookie('userId', '', -1),
						buildCookie('groups', '', -1),
						buildCookie('parentUserId', '', -1),
					]
					logOutCookies.forEach((cookie) => {
						document.cookie = cookie
					})
					store.dispatch(authDetermined())
				},
				href: getLogoutUrl(),
				target: '_self',
			},
		],
		[userGroups.brand]: [
			{
				label: 'My Dares',
				routeId: BRAND_DARES_ROUTE_ID,
			},
			{ label: 'Approve Dares', routeId: BRAND_PENDING_DELIVERIES_ROUTE_ID },
			{
				label: 'Audience targeting',
				href: routeUrls.audienceTargeting(),
			},
		],
		[userGroups.agency]: [
			{ label: 'Approve Dares', routeId: BRAND_PENDING_DELIVERIES_ROUTE_ID },
		],
		[userGroups.admin]: [
			{
				label: 'My dares',
				routeId: BRAND_DARES_ROUTE_ID,
			},
			{
				label: 'Brands reports',
				routeId: BRANDS_REPORTS_ROUTE_ID,
			},
			{
				label: 'Audience targeting',
				href: routeUrls.audienceTargeting(),
			},
			{
				label: 'Ban admin',
				routeId: ADMIN_BAN_IP_FORM_ROUTE_ID,
			},
			{
				label: 'Pending Deliveries',
				routeId: PENDING_DELIVERIES_ROUTE_ID,
			},
			{
				label: 'Dashboard Demo',
				routeId: DEMO_CAMPAIGN_DASHBOARD_ROUTE_ID,
			},
			{
				label: 'Payouts list',
				routeId: PAYOUT_ADMIN_LIST_ROUTE_ID,
			},
			{
				label: 'Coupon Admin',
				routeId: COUPON_ADMIN_ROUTE_ID,
			},
			{
				label: 'Featured dares',
				routeId: FEATURED_DARES_ADMIN_ROUTE_ID,
			},
			{
				label: 'Pending brand users',
				routeId: PENDING_BRANDS_USERS_ADMIN_ROUTE_ID,
			},
			{
				label: 'Drop campaigns',
				routeId: DROP_EVENTS_ADMIN_PANEL_ROUTE_ID,
			},
			{
				label: 'Short Url Report',
				routeId: SHORT_URL_REPORT_ROUTE_ID,
			},
		],
	}
	const menuItems = [
		...menuItemsMap.commonTop,
		...(menuItemsMap[userRole] || []),
		...menuItemsMap.commonBottom,
	]

	return isMobileResSelector(state, props)
		? [{
			icon: 'menu',
			menuItems: [
				...reject(isNil, prop(userRole, linksMap)),
				...(isAuthenticated(state, props)
					? menuItems
					: [{ label: 'Sign In', routeId: LOGIN_ROUTE_ID }]
				),
			],
		}]
		: [
			...reject(isNil, prop(userRole, linksMap)),
			{ moduleId: LANGUAGE_SWITCHER_MODULE_ID },
			...(isAuthenticated(state, props)
				? [{
					label: userAccountDisplayNameSelector(state),
					avatar: imageSelector(state),
					menuItems,
				}]
				: [{ label: 'Sign In', routeId: LOGIN_ROUTE_ID, withBorder: true }]
			),
		]
}
