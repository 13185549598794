import { Badge } from '@mui/material';
import { memo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
export const NewFeatureBadge = memo((props) => {
    const { x = 1.5, y = -1.3, children } = props;
    const { isBadgeVisible, onClick, } = useNewFeatureBadge(props);
    if (!isBadgeVisible) {
        return children;
    }
    return (<Badge onClick={onClick} anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
        }} slotProps={{
            badge: {
                style: {
                    transform: `translate(${x}rem, ${y}rem)`,
                },
            },
        }} color="primary" badgeContent="New">
			{children}
		</Badge>);
});
const CLICK_COUNT_TO_HIDE = 1;
const LOCAL_STORAGE_KEY = 'featureMetadata';
export const useFeatureMetadata = () => {
    return useLocalStorage(LOCAL_STORAGE_KEY, {});
};
export const useNewFeatureBadge = (props) => {
    var _a;
    const { featureId } = props;
    const [featureMetadata, setFeatureMetadata] = useFeatureMetadata();
    const featureClickCount = ((_a = featureMetadata[featureId]) === null || _a === void 0 ? void 0 : _a.clickCount) || 0;
    const isBadgeVisible = featureClickCount < CLICK_COUNT_TO_HIDE;
    const onClick = () => {
        setFeatureMetadata((prev) => {
            var _a, _b;
            return Object.assign(Object.assign({}, prev), { [featureId]: Object.assign(Object.assign({}, prev[featureId]), { clickCount: ((_b = (_a = prev[featureId]) === null || _a === void 0 ? void 0 : _a.clickCount) !== null && _b !== void 0 ? _b : 0) + 1 }) });
        });
    };
    return {
        isBadgeVisible,
        onClick,
    };
};
