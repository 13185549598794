import moment from 'moment-mini';
import { Platform } from 'root/src/shared/@types/platforms';
import { DashboardOrderSortTypes, CreatorsTableTab } from 'root/src/shared/@types/dashboard';
export const defaultDashboardFilters = {
    from: moment()
        .subtract(7, 'days')
        .format(),
    to: moment().format(),
};
export const defaultCreatorsTableFilters = {
    ambassadorsOnly: false,
    platforms: [Platform.Twitch],
    currentPage: 1,
    sortBy: 'minutesViewed',
    sort: DashboardOrderSortTypes.DESC,
};
export const defaultCreatorsTableTab = (dropEvent) => {
    if (!dropEvent) {
        return CreatorsTableTab.Participating;
    }
    return CreatorsTableTab.Participating;
};
export const defaultCreatorsTableSortBy = (tab) => {
    if (tab === CreatorsTableTab.Waitlist) {
        return 'followerCount';
    }
    return 'minutesViewed';
};
