import { surveyEndpointIds } from 'root/src/shared/survey/constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { gatewayEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const surveySchema = {
    type: 'object',
    properties: {
        id: { type: 'string' },
        dropId: { type: 'string' },
        url: { type: 'string' },
        surveyDate: { type: 'string' },
        type: { type: 'string' },
    },
};
export const getSurveysEndpoint = {
    [surveyEndpointIds.GET_SURVEYS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: gatewayEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                dropId: { type: 'string' },
            },
            required: ['dropId'],
        },
        responseSchema: {
            type: 'array',
            items: surveySchema,
        },
    },
};
