import path from 'ramda/src/path'
import updateEvent from 'root/src/client/logic/dropEvent/actions/updateEvent'
import invokeApiRequest from 'root/src/client/logic/api/util/invokeApiRequest'
import pick from 'ramda/src/pick'
import { dropEventStatusEnum } from 'root/src/shared/constants/dropEvent'
import has from 'ramda/src/has'
import { dropEndpointIds } from 'root/src/shared/drops/drops.constants'

export const dropEventDetailsProps = [
	'daresCompleted', 'daresAccepted', 'creatorsStreamed',
	'totalWatchTime', 'totalStreamTime', 'totalAvgCcv',
]

export default recordId => async (dispatch, getState) => {
	const state = getState()
	const dropEvent = path(['api', 'records', 'dropEvent', recordId], state)
	const hasBeenUpdated = dropEventDetailsProps.some(
		prop => has(prop, dropEvent),
	)
	if (dropEvent.status !== dropEventStatusEnum.ACTIVE || hasBeenUpdated) return
	const { body, statusCode } = await invokeApiRequest(
		dropEndpointIds.GET_DROP_STATISTICS,
		{ dropId: recordId },
		state,
	)
	const success = statusCode === 200
	if (!success) return

	dispatch(updateEvent({ ...dropEvent, ...pick(dropEventDetailsProps, body) }))
}
