import { CreatorsTableTab, DashboardOrderSortTypes } from 'root/src/shared/@types/dashboard';
export const getDashboardPayloadSchema = {
    type: 'object',
    properties: {
        limit: {
            type: 'integer',
            minimum: 0,
            maximum: 100,
        },
        sort: {
            type: 'string',
            enum: [
                DashboardOrderSortTypes.ASC,
                DashboardOrderSortTypes.DESC,
            ],
        },
        sortBy: { type: 'string' },
        from: { type: 'string' },
        to: { type: 'string' },
        mnemonicIds: {
            type: 'array',
            items: {
                type: 'string',
            },
        },
        filters: { type: 'object' },
        currentPage: { type: 'integer' },
        tab: {
            type: 'string',
            enum: [
                CreatorsTableTab.Waitlist,
                CreatorsTableTab.Participating,
            ],
        },
    },
    additionalProperties: false,
};
