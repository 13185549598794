import { memo } from 'react';
import { useNewFeatureBadge } from 'root/src/client/v2/common/components/badge/NewFeatureBadge';
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls';
import { trpc } from 'root/src/client/v2/trpc/trpc';
import { useIsClipReviewer } from 'root/src/client/v2/auth/hooks/useIsClipReviewer';
export const PendingReviewsOverlay = memo(({ children }) => {
    var _a;
    const { isClipReviewer } = useIsClipReviewer();
    const { isBadgeVisible } = useNewFeatureBadge({ featureId: routeUrls.reviewClips() });
    const isVisible = isClipReviewer && !isBadgeVisible;
    const pendingReviewCount = trpc.communityReview.getPendingReviewsCount.useQuery(undefined, {
        enabled: isVisible,
        refetchInterval: 10 * 1000,
    });
    const reviewCount = (_a = pendingReviewCount === null || pendingReviewCount === void 0 ? void 0 : pendingReviewCount.data) !== null && _a !== void 0 ? _a : 0;
    if (!isVisible || !reviewCount) {
        return children;
    }
    return (<div className="relative flex flex-initial items-center">
			<div className="absolute right-4 min-[960px]:-right-3 min-[960px]:-top-4 shadow drop-shadow px-1.5 h-4 text-xs uppercase flex flex-initial justify-center font-bold items-center bg-pink text-dark-4 rounded-full">
				{reviewCount}
			</div>
			{children}
		</div>);
});
