import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth'

import {
	CLAIM_PROJECT_FORM_MODULE_ID,
	CREATE_PROJECT_FORM_MODULE_ID,
	DARE_DELIVERY_DETAIL_MODULE_ID,
	DELIVERY_DARE_FORM_MODULE_ID,
	FEATURED_DARES_LIST_MODULE_ID,
	CREATOR_PROJECTS_LIST_MODULE_ID,
	MY_PROJECT_BANNER_HEADER_MODULE_ID,
	PENDING_DELIVERIES_MODULE_ID,
	PLEDGE_PROJECT_FORM_MODULE_ID,
	STEPPER_HEADER_MODULE_ID,
	TITLE_HEADER_PENDING_DELIVERIES_MODULE_ID,
	VIEW_PROJECT_MODULE_ID,
	EDIT_PROFILE_FORM_MODULE_ID,
	PROJECT_COMMENTS_LIST_MODULE_ID,
	PROJECT_COMMENTS_FORM_MODULE_ID,
	COMPLETED_DARES_LIST_MODULE_ID,
	MY_PROJECTS_LIST_MODULE_ID,
	CREATE_BRAND_PROJECT_FORM_MODULE_ID,
	CREATORS_SECTION_MODULE_ID,
	CREATORS_AUTOCOMPLETE_FORM_MODULE_ID,
	BRANDS_TESTIMONIALS_MODULE_ID,
	CREATORS_TESTIMONIALS_MODULE_ID,
	BRANDS_CONTACTS_SECTION_MODULE_ID,
	DROP_EVENT_PAGE_MODULE_ID,
	DROP_PREVIEW_EVENT_PAGE_MODULE_ID,
	KEYS_AND_CODES_FOR_CREATOR_MODULE_ID,
	MY_INITIAL_PRODUCTS_MODULE_ID,
	VIEWERS_SHOWCASE_MODULE_ID,
	HOMEPAGE_ROLES_SECTION,
	HOMEPAGE_PUBLISHERS_SECTION,
	HOMEPAGE_CREATORS_SECTION,
	DROPS_SLIDER_MODULE_ID,
	SOCIAL_ICONS_MODULE_ID,
	CREATORS_PAGE_HEAD_SECTION,
	CREATORS_FEATURES_MODULE_ID,
	HOMEPAGE_TESTIMONIALS_MODULE_ID,
	BRANDS_DARE_CREATORS_SECTION,
	BRANDS_PARTNERSHIPS_SECTION,
	BRANDS_CREATORS_SECTION,
	STATISTICS_SECTION_MODULE_ID,
	BRANDS_FEATURES_MODULE_ID,
	BRANDS_SLIDER_MODULE_ID,
	CREATORS_PAGE_SECTIONS_MODULE_ID,
	BRAND_PENDING_DELIVERIES_MODULE_ID,
	BRAND_DARE_DELIVERY_DETAIL_MODULE_ID,
	REQUEST_INVOICE_FORM_MODULE_ID,
	GET_STARTED_MODULE_ID,
	SUBMIT_EMAIL_FORM_MODULE_ID,
	KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID,
} from 'root/src/shared/descriptions/modules/moduleIds'

import {
	CLAIM_PROJECT_ROUTE_ID,
	CREATE_PROJECT_ROUTE_ID,
	DARE_DELIVERY_DETAIL_ROUTE_ID,
	DELIVERY_DARE_FORM_ROUTE_ID,
	MY_PROJECTS_ROUTE_ID,
	PENDING_DELIVERIES_ROUTE_ID,
	PLEDGE_PROJECT_ROUTE_ID,
	VIEW_PROJECT_ROUTE_ID,
	CREATORS_PAGE_ROUTE_ID,
	EDIT_PROFILE_ROUTE_ID,
	CREATE_BRAND_PROJECT_ROUTE_ID,
	PUBLISHERS_PAGE_ROUTE_ID,
	BRAND_DARE_DELIVERY_DETAIL_ROUTE_ID,
	DROP_EVENT_PAGE_ROUTE_ID,
	DROP_PREVIEW_EVENT_PAGE_ROUTE_ID,
	CREATOR_KEYS_ROUTE_ID,
	VIEWERS_PAGE_ROUTE_ID,
	BRAND_PENDING_DELIVERIES_ROUTE_ID,
	REQUEST_INVOICE_FORM_ROUTE_ID,
	CREATORS_LEADERBOARD_ROUTE_ID,
	REDIRECT_ROUTE_ID,
} from 'root/src/shared/descriptions/routes/routeIds'

export const homepageModules = [
	HOMEPAGE_ROLES_SECTION,
	HOMEPAGE_PUBLISHERS_SECTION,
	HOMEPAGE_CREATORS_SECTION,
	DROPS_SLIDER_MODULE_ID,
	HOMEPAGE_TESTIMONIALS_MODULE_ID,
	SOCIAL_ICONS_MODULE_ID,
]

export default {
	[CREATE_PROJECT_ROUTE_ID]: {
		noSitemap: true,
		url: '/create-project',
		modules: [
			CREATE_PROJECT_FORM_MODULE_ID,
		],
	},
	[CREATE_BRAND_PROJECT_ROUTE_ID]: {
		noSitemap: true,
		url: '/create-brand-project',
		modules: [
			CREATE_BRAND_PROJECT_FORM_MODULE_ID,
		],
	},
	[BRAND_DARE_DELIVERY_DETAIL_ROUTE_ID]: {
		noSitemap: true,
		url: '/review-drop-project/:recordId',
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		modules: [
			BRAND_DARE_DELIVERY_DETAIL_MODULE_ID,
		],
	},
	[DARE_DELIVERY_DETAIL_ROUTE_ID]: {
		noSitemap: true,
		url: '/review-project/:recordId',
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			DARE_DELIVERY_DETAIL_MODULE_ID,
		],
	},
	[VIEW_PROJECT_ROUTE_ID]: {
		url: '/dare/:recordId',
		modules: [
			VIEW_PROJECT_MODULE_ID,
			COMPLETED_DARES_LIST_MODULE_ID,
			PROJECT_COMMENTS_FORM_MODULE_ID,
			PROJECT_COMMENTS_LIST_MODULE_ID,
		],
		mainModule: PROJECT_COMMENTS_LIST_MODULE_ID,
	},
	[CLAIM_PROJECT_ROUTE_ID]: {
		url: '/claim-project/:recordId',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		makeRequestsOnClient: true,
		modules: [
			CLAIM_PROJECT_FORM_MODULE_ID,
		],
	},
	[BRAND_PENDING_DELIVERIES_ROUTE_ID]: {
		url: '/brand-approve-dares',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.brandUser,
		modules: [
			TITLE_HEADER_PENDING_DELIVERIES_MODULE_ID,
			BRAND_PENDING_DELIVERIES_MODULE_ID,
		],
	},
	[PENDING_DELIVERIES_ROUTE_ID]: {
		url: '/pending-videos',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		authenticationRole: userGroups.admin,
		modules: [
			TITLE_HEADER_PENDING_DELIVERIES_MODULE_ID,
			PENDING_DELIVERIES_MODULE_ID,
		],
		mainModule: PENDING_DELIVERIES_MODULE_ID,
	},
	[REDIRECT_ROUTE_ID]: {
		url: '/redirect',
		modules: [],
	},
	[MY_PROJECTS_ROUTE_ID]: {
		url: '/my-projects',
		authentication: authenticationTypes.authenticated,
		modules: [
			MY_PROJECT_BANNER_HEADER_MODULE_ID,
			MY_PROJECTS_LIST_MODULE_ID,
		],
		mainModule: CREATOR_PROJECTS_LIST_MODULE_ID,
	},
	[PLEDGE_PROJECT_ROUTE_ID]: {
		noSitemap: true,
		url: '/pledge-project/:recordId',
		modules: [
			PLEDGE_PROJECT_FORM_MODULE_ID,
		],
	},
	[DELIVERY_DARE_FORM_ROUTE_ID]: {
		url: '/delivery-dare/:recordId',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		modules: [
			DELIVERY_DARE_FORM_MODULE_ID,
		],
	},
	[CREATORS_PAGE_ROUTE_ID]: {
		url: '/creators',
		chatWidget: true,
		discordWidget: true,
		modules: [
			CREATORS_PAGE_HEAD_SECTION,
			CREATORS_PAGE_SECTIONS_MODULE_ID,
			DROPS_SLIDER_MODULE_ID,
			CREATORS_TESTIMONIALS_MODULE_ID,
			CREATORS_FEATURES_MODULE_ID,
		],
		redirects: [
			{ source: '/extensions' },
		],
	},
	[CREATORS_LEADERBOARD_ROUTE_ID]: {
		url: '/creators-leaderboard',
		chatWidget: true,
		discordWidget: true,
		modules: [
		],
	},
	[EDIT_PROFILE_ROUTE_ID]: {
		url: '/edit-profile',
		noSitemap: true,
		authentication: authenticationTypes.authenticated,
		modules: [
			EDIT_PROFILE_FORM_MODULE_ID,
		],
	},
	[PUBLISHERS_PAGE_ROUTE_ID]: {
		url: '/publishers',
		chatWidget: true,
		discordWidget: true,
		modules: [
			BRANDS_DARE_CREATORS_SECTION,
			SUBMIT_EMAIL_FORM_MODULE_ID,
			BRANDS_PARTNERSHIPS_SECTION,
			BRANDS_CREATORS_SECTION,
			BRANDS_FEATURES_MODULE_ID,
			BRANDS_SLIDER_MODULE_ID,
			STATISTICS_SECTION_MODULE_ID,
			BRANDS_TESTIMONIALS_MODULE_ID,
		],
		redirects: [
			{ source: '/brands' },
		],
	},
	[DROP_EVENT_PAGE_ROUTE_ID]: {
		url: '/e/:recordId',
		chatWidget: true,
		discordWidget: true,
		modules: [
			GET_STARTED_MODULE_ID,
			DROP_EVENT_PAGE_MODULE_ID,
		],
		redirects: [
			{ source: '/events/:recordId' },
			{
				source: '/monstrum-2',
				destination: '/e/monstrum-2',
			},
			{
				source: '/killer-queen-black',
				destination: '/e/killer-queen-black',
			},
			{
				source: '/run-prop-run',
				destination: '/e/run-prop-run',
			},
			{
				source: '/swimsanity',
				destination: '/e/swimsanity',
			},
			{
				source: '/heaven-dust',
				destination: '/e/heaven-dust',
			},
			{
				source: '/barricadez',
				destination: '/e/barricadez',
			},
			{
				source: '/super-cable-boy',
				destination: '/e/super-cable-boy',
			},
			{
				source: '/e/swimsanity-',
				destination: '/e/swimsanity',
			},
			{
				source: '/e/light-nite-',
				destination: '/e/light-nite',
			},
		],
	},
	[DROP_PREVIEW_EVENT_PAGE_ROUTE_ID]: {
		url: '/drop-preview/:recordId',
		chatWidget: true,
		discordWidget: true,
		modules: [
			DROP_PREVIEW_EVENT_PAGE_MODULE_ID,
		],
	},
	[CREATOR_KEYS_ROUTE_ID]: {
		url: '/my-keys',
		authentication: authenticationTypes.authenticated,
		variantModules: [
			{ label: 'My Keys', modules: [KEYS_AND_CODES_FOR_CREATOR_MODULE_ID, MY_INITIAL_PRODUCTS_MODULE_ID] },
			{
				label: 'Keys for Giveaways',
				authenticationRole: userGroups.betaTester,
				modules: [KEYS_AND_CODES_FOR_VIEWERS_MODULE_ID],
			},
		],
	},
	[VIEWERS_PAGE_ROUTE_ID]: {
		url: '/viewers',
		experimentVariants: [
			{
				value: 'Control',
				modules: [
					FEATURED_DARES_LIST_MODULE_ID,
					STEPPER_HEADER_MODULE_ID,
					CREATORS_AUTOCOMPLETE_FORM_MODULE_ID,
					CREATORS_SECTION_MODULE_ID,
					VIEWERS_SHOWCASE_MODULE_ID,
					BRANDS_CONTACTS_SECTION_MODULE_ID,
				],
			},
			{
				value: 'Creators Top',
				modules: [
					CREATORS_AUTOCOMPLETE_FORM_MODULE_ID,
					CREATORS_SECTION_MODULE_ID,
					STEPPER_HEADER_MODULE_ID,
					FEATURED_DARES_LIST_MODULE_ID,
					VIEWERS_SHOWCASE_MODULE_ID,
					BRANDS_CONTACTS_SECTION_MODULE_ID,
				],
			},
		],
		modules: [FEATURED_DARES_LIST_MODULE_ID, CREATORS_SECTION_MODULE_ID],
		redirects: [
			{ source: '/dares' },
		],
	},
	[REQUEST_INVOICE_FORM_ROUTE_ID]: {
		url: '/request-invoice/:recordId',
		modules: [
			REQUEST_INVOICE_FORM_MODULE_ID,
		],
	},
}
