import { communityReviewEndpointIds } from 'root/src/server/modules/communityReview/communityReview.fixture';
export const assignCommunityReviewEndpoint = {
    [communityReviewEndpointIds.ASSIGN_COMMUNITY_REVIEW]: {
        payloadSchema: {
            type: 'object',
            properties: {
                reviewerId: { type: 'string' },
                projectId: { type: 'string' },
            },
        },
    },
};
