import { HOW_TO_EARN_POINTS_MODAL_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds';
import { colorsNew } from 'root/src/client/web/commonStyles';
import { LinkTermTypesEnum } from 'root/src/client/v2/drop/components/SponsorshipTerms/types';
import { createLinkParametrizedIndex, } from 'root/src/client/v2/drop/components/SponsorshipTerms/helpers';
import { PointsApplicationState } from 'root/src/server/modules/points/types/types';
import { getDateWithTimezone } from 'root/src/shared/util/getDateWithTimezone';
import * as moment from 'moment';
export const sponsorshipRegularTerms = [
    {
        text: 'Put “#daredrop” in the stream title.',
        color: colorsNew.teal,
    },
    {
        color: colorsNew.primary,
        text: `Link to ${createLinkParametrizedIndex(0)} in chat.`,
        links: [{ linkType: LinkTermTypesEnum.SHORT_LINK }],
        visibilityCondition: ({ shortUrl }) => Boolean(shortUrl),
    },
    {
        color: colorsNew.primary,
        text: 'Link to (link created after you join) in chat.',
        visibilityCondition: ({ shortUrl }) => !shortUrl,
    },
    {
        text: ({ dropEvent }) => {
            var _a;
            if (typeof dropEvent.pointsStreamLimit === 'undefined') {
                return '';
            }
            return `The points you earn for this drop are based on your most viewed ${dropEvent.pointsStreamLimit} minutes of content. Using your average viewership, you will earn up to ${(_a = dropEvent.estimatedPointsToEarn) === null || _a === void 0 ? void 0 : _a.toLocaleString()} points.`;
        },
        color: colorsNew.yellow,
        visibilityCondition: ({ canEarnPoints, dropEvent }) => Boolean(canEarnPoints) && (dropEvent.pointsStreamLimit || 0) > 0,
    },
    {
        text: 'Earn Dare rewards until the Dares expire.',
        color: colorsNew.pink,
    },
    {
        text: ({ dropEvent }) => {
            const { pointsLimit, estimatedPointsToEarn, pointsAwarded, pointsApplicationState, pointsReservationHours, isCreatorPointsAllowlisted, } = dropEvent;
            const reservedPoints = Math.max(0, Math.round(Math.min(pointsLimit - pointsAwarded - dropEvent.pointsReserved, estimatedPointsToEarn)));
            // TODO add case when rejected for terms
            const isDropLive = dropEvent.isLive;
            const isApplicationUnresolved = pointsApplicationState === undefined || pointsApplicationState === PointsApplicationState.PENDING;
            if (isApplicationUnresolved && !isCreatorPointsAllowlisted && !isDropLive) {
                const start = getDateWithTimezone(dropEvent.liveDate);
                const end = getDateWithTimezone(moment.utc(dropEvent.liveDate).add(pointsReservationHours, 'hours').format());
                return `Once your application will be approved and drop goes live, Dare Drop will reserve up to ${reservedPoints.toLocaleString()} points for you for ${pointsReservationHours} hours - from ${start} to ${end}.`;
            }
            if (isApplicationUnresolved && !isCreatorPointsAllowlisted && isDropLive) {
                return `Once your application will be approved, Dare Drop will reserve up to ${reservedPoints.toLocaleString()} points for you for ${pointsReservationHours} hours.`;
            }
            if (isDropLive) {
                return `Once you accept these terms, Dare Drop will reserve up to ${reservedPoints.toLocaleString()} points for you for ${pointsReservationHours} hours. After that, points are on a first come, first served basis.`;
            }
            const start = getDateWithTimezone(dropEvent.liveDate);
            const end = getDateWithTimezone(moment.utc(dropEvent.liveDate).add(pointsReservationHours, 'hours').format());
            return `Once this drop goes live, Dare Drop will reserve up to ${reservedPoints.toLocaleString()} points for you for ${pointsReservationHours} hours - from ${start} to ${end}. After that, points are rewarded on a first come - first serve basis only to creators approved for this Drop, who did not meet their streaming deadline.`;
        },
        color: colorsNew.green,
        visibilityCondition: ({ canEarnPoints, isTermsAccepted, canUserReclaimReservation }) => {
            return Boolean(canEarnPoints) && (!isTermsAccepted || Boolean(canUserReclaimReservation));
        },
    },
];
export const howToEarnPointsModal = {
    [HOW_TO_EARN_POINTS_MODAL_MODULE_ID]: {
        moduleType: 'modal',
        modalTitle: 'Drop Parameters',
        modalText: ({ dropEvent }) => {
            if (dropEvent.isLive) {
                return 'Welcome to our new points program! Here\'s what you need to do to earn points on this drop:';
            }
            return 'Welcome to our new points program! Your participation will only count when the drop is live. Here\'s what you need to do to earn points for this drop:';
        },
        modalContent: {
            buttonText: 'CONTINUE',
            regularTerms: sponsorshipRegularTerms,
            whitelistedTerms: [
                {
                    text: ({ dropEvent }) => `You are a partner of ${dropEvent.brand.brandName}, so you won't get points on this drop`,
                    color: colorsNew.teal,
                },
                {
                    text: 'But you can still earn Dare rewards until the Dares expire.',
                    color: colorsNew.pink,
                },
            ],
            termsAgreementText: 'I agree to the terms for earning Points on this Drop.',
        },
    },
};
