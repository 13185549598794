import { brandEndpointIds } from 'root/src/shared/brand/brand.constants';
import { authenticationTypes } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const scheduleDemoEndpoint = {
    [brandEndpointIds.SCHEDULE_DEMO]: {
        authentication: authenticationTypes.unauthenticated,
        payloadSchema: {
            type: 'object',
            properties: {
                name: { type: 'string' },
                email: { type: 'string' },
                role: { type: 'string' },
            },
            required: ['name', 'email', 'role'],
        },
        endpointType: otherEndpointType,
    },
};
