export var DropSerializer;
(function (DropSerializer) {
    DropSerializer["LIST_VIEW"] = "DROP_LIST_VIEW_SERIALIZER";
})(DropSerializer || (DropSerializer = {}));
export const dropEndpointIds = {
    ON_COMPLETED_DROP: 'ON_COMPLETED_DROP',
    UNCOMPLETED_PENDING_DROPS: 'UNCOMPLETED_PENDING_DROPS',
    UNCOMPLETED_DROP_DARES_REMINDER: 'UNCOMPLETED_DROP_DARES_REMINDER',
    DROP_GET_EVENT: 'DROP_GET_EVENT',
    GET_DROP_MEDIA: 'GET_DROP_MEDIA',
    GET_DROP_WATCH_TIME_PREDICTION: 'GET_DROP_WATCH_TIME_PREDICTION',
    SET_EMAIL_SUBSCRIPTION_DROP: 'SET_EMAIL_SUBSCRIPTION_DROP',
    GET_USER_CAMPAIGN_DATA: 'GET_USER_CAMPAIGN_DATA',
    TRAIN_DROP_WATCH_TIME_PREDICTION: 'TRAIN_DROP_WATCH_TIME_PREDICTION',
    GET_DROP_STATISTICS: 'GET_DROP_STATISTICS',
};
