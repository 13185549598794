import { CommunityReviewStatus } from 'root/src/shared/communityReview/communityReview.types';
/**
 * Readable community review statuses that can be assigned to a review
 */
export const READABLE_AUDIT_STATUS = {
    [CommunityReviewStatus.Approved]: 'Approved',
    [CommunityReviewStatus.Rejected]: 'Rejected',
    [CommunityReviewStatus.Pending]: 'Pending',
};
/**
 * Interval in seconds for the heartbeat to check if the community review is still alive
 */
export const COMMUNITY_REVIEW_HEARTBEAT_INTERVAL = 5;
export var CommunityReviewFilter;
(function (CommunityReviewFilter) {
    CommunityReviewFilter["NeedsAttention"] = "NEEDS_ATTENTION";
    CommunityReviewFilter["Featured"] = "FEATURED";
    CommunityReviewFilter["InReview"] = "IN_REVIEW";
    CommunityReviewFilter["NeedsReview"] = "NEEDS_REVIEW";
    CommunityReviewFilter["NeedsReward"] = "NEEDS_REWARD";
    CommunityReviewFilter["OneApproval"] = "ONE_APPROVAL";
    CommunityReviewFilter["Rejected"] = "REJECTED";
    CommunityReviewFilter["NeedsFirstReview"] = "NEEDS_FIRST_REVIEW";
})(CommunityReviewFilter || (CommunityReviewFilter = {}));
export const READABLE_COMMUNITY_REVIEW_FILTER = {
    [CommunityReviewFilter.NeedsAttention]: 'Needs Attention',
    [CommunityReviewFilter.Featured]: 'Featured',
    [CommunityReviewFilter.InReview]: 'In Review',
    [CommunityReviewFilter.NeedsReview]: 'Needs Review',
    [CommunityReviewFilter.NeedsReward]: 'Needs Reward',
    [CommunityReviewFilter.OneApproval]: 'One Approval',
    [CommunityReviewFilter.Rejected]: 'Rejected',
    [CommunityReviewFilter.NeedsFirstReview]: 'Needs First Review',
};
