import { brandEndpointIds } from 'root/src/shared/brand/brand.constants';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { otherEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
export const wipeAccountEndpoint = {
    [brandEndpointIds.WIPE_ACCOUNT]: {
        payloadSchema: {
            type: 'object',
            properties: {
                brandId: { type: 'string' },
            },
            required: ['brandId'],
        },
    },
    [brandEndpointIds.WIPE_ACCOUNT_TRIGGER]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.admin,
        endpointType: otherEndpointType,
        payloadSchema: {
            type: 'object',
            properties: {
                brandId: { type: 'string' },
            },
            required: ['brandId'],
        },
    },
};
