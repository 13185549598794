import { listEndpointType } from 'root/src/shared/descriptions/endpoints/lenses';
import { publisherBan } from 'root/src/shared/descriptions/endpoints/recordTypes';
import { authenticationTypes, userGroups } from 'root/src/shared/constants/auth';
import { Platform } from 'root/src/shared/@types/platforms';
import { PublisherBanListType } from 'root/src/shared/@types/publisherBanList';
import { publisherListsEndpointIds } from 'root/src/shared/publisherLists/publisherLists.constants';
export const addPublisherBannedCreatorsEndpoint = {
    [publisherListsEndpointIds.ADD_PUBLISHER_BANNED_CREATORS]: {
        authentication: authenticationTypes.authenticated,
        authenticationRole: userGroups.brand,
        endpointType: listEndpointType,
        recordType: publisherBan,
        payloadSchema: {
            type: 'object',
            properties: {
                creators: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            mnemonicId: { type: 'string' },
                            platform: {
                                type: 'string',
                                enum: Object.values(Platform),
                            },
                        },
                    },
                },
                banListType: {
                    type: 'string',
                    enum: Object.values(PublisherBanListType),
                },
                dropEventId: {
                    type: 'string',
                },
            },
            required: ['creators', 'banListType'],
        },
    },
};
