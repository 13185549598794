import { dropEventStatusEnum } from 'root/src/shared/constants/dropEvent';
import moment from 'moment-mini';
import { useDropEvents } from 'root/src/client/logic/app/hooks/useDropEvents';
import { isDropComingSoon } from 'root/src/shared/drops/helpers/isDropComingSoon.helper';
export function useDropEventsList() {
    const dropEvents = useDropEvents();
    const liveAndExpiredDropEvents = dropEvents.filter(dropEvent => !isPendingDrop(dropEvent));
    return [
        ...liveAndExpiredDropEvents.filter(dropEvent => isLiveOrComingSoonDropEvent(dropEvent)).sort(sortByDate),
        ...liveAndExpiredDropEvents.filter(dropEvent => !isLiveOrComingSoonDropEvent(dropEvent)).sort(sortByDate),
    ];
}
function isLiveOrComingSoonDropEvent(dropEvent) {
    if (dropEvent.isLive) {
        return true;
    }
    return isDropComingSoon(dropEvent);
}
function isPendingDrop(dropEvent) {
    const { status } = dropEvent;
    return (status === dropEventStatusEnum.PENDING_APPROVAL
        || status === dropEventStatusEnum.PENDING_SUBSCRIPTION);
}
function sortByDate(a, b) {
    return moment(a.liveDate).isSameOrAfter(b.liveDate) ? -1 : 1;
}
