import { CAMPAIGN_REPORT_LIST_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { dropEventAccordionListType } from 'root/src/shared/constants/moduleTypes'
import { userEventsKey } from 'root/src/shared/constants/keys'
import { dropEvent } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { GET_DROP_EVENTS } from 'root/src/shared/descriptions/endpoints/endpointIds'
import {
	CAMPAIGNS_PAGE_ROUTE_ID,
	DROP_EVENT_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import equals from 'ramda/src/equals'
import currentRouteId from 'root/src/client/logic/route/selectors/currentRouteId'
import publishDropEvent from 'root/src/client/logic/dropEvent/thunks/publishDropEvent'
import editDropEvent from 'root/src/client/logic/dropEvent/thunks/editDropEvent'
import { dropEventSubscriptionStatusEnum } from 'root/src/shared/constants/dropEvent'
import getDropEventDetails from 'root/src/client/logic/dropEvent/thunks/getDropEventDetails'
import pushRouteClient from 'root/src/client/logic/route/util/pushRouteClient'
import { routeUrls } from 'root/src/shared/descriptions/routes/routeUrls'

export default {
	[CAMPAIGN_REPORT_LIST_MODULE_ID]: {
		moduleType: 'list',
		listType: dropEventAccordionListType,
		listPayload: {
			section: userEventsKey,
			pageItemLength: 20,
		},
		actionButtons: [
			{
				label: 'Publish',
				action: publishDropEvent,
				condition: ({ subscriptionStatus }) => subscriptionStatus
					!== dropEventSubscriptionStatusEnum.ACTIVE,
			},
			{
				label: 'Go to page',
				action: item => () => pushRouteClient({
					routeId: DROP_EVENT_PAGE_ROUTE_ID, routeParams: {	recordId: item.mnemonicId },
				}),
			},
			{
				label: 'Edit campaign',
				action: editDropEvent,
			},
		],
		accordionOnExpand: getDropEventDetails,
		recordPageType: dropEvent,
		endpointId: [GET_DROP_EVENTS],
		buttons: [{
			routeId: routeUrls.addCampaign(),
			label: 'Add New Campaign',
			condition: state => equals(CAMPAIGNS_PAGE_ROUTE_ID, currentRouteId(state)),
		}],
	},
}
