export var CommunityReviewStatus;
(function (CommunityReviewStatus) {
    CommunityReviewStatus["Approved"] = "approved";
    CommunityReviewStatus["Rejected"] = "rejected";
    CommunityReviewStatus["Pending"] = "pending";
})(CommunityReviewStatus || (CommunityReviewStatus = {}));
export var CommunityReviewEventType;
(function (CommunityReviewEventType) {
    CommunityReviewEventType["PlayerStarted"] = "PLAYER_STARTED";
})(CommunityReviewEventType || (CommunityReviewEventType = {}));
