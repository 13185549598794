import pipe from 'ramda/src/pipe'
import pluck from 'ramda/src/pluck'
import values from 'ramda/src/values'
import map from 'ramda/src/map'
import head from 'ramda/src/head'
import uniqBy from 'ramda/src/uniqBy'
import prop from 'ramda/src/prop'
import { GET_PENDING_DELIVERIES } from 'root/src/shared/descriptions/endpoints/endpointIds'
import { PENDING_DELIVERIES_MODULE_ID } from 'root/src/shared/descriptions/modules/moduleIds'
import { project } from 'root/src/shared/descriptions/endpoints/recordTypes'
import { cardListType } from 'root/src/shared/constants/moduleTypes'
import { DARE_DELIVERY_DETAIL_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { sectionKey, firstTimeDropperKey } from 'root/src/shared/constants/keys'
import {
	CommunityReviewFilter,
	READABLE_COMMUNITY_REVIEW_FILTER,
} from 'root/src/shared/communityReview/communityReview.fixtures'

const COMMUNITY_REVIEW_OPTIONS = [
	{
		label: 'All',
		payload: {
			communityReviewFilters: [],
		},
	},
	...Object.values(CommunityReviewFilter).map(filter => ({
		label: READABLE_COMMUNITY_REVIEW_FILTER[filter],
		payload: {
			communityReviewFilters: [filter],
		},
	})),
]

export default {
	[PENDING_DELIVERIES_MODULE_ID]: {
		moduleType: 'list',
		listType: cardListType,
		listPayload: {
			all: true,
		},
		recordType: project,
		endpointId: [
			GET_PENDING_DELIVERIES,
		],
		listRouteId: DARE_DELIVERY_DETAIL_ROUTE_ID,
		goTopButton: true,
		listModuleId: PENDING_DELIVERIES_MODULE_ID,
		embeddedContent: {
			moduleType: 'embeddedForm',
			fields: [
				{
					fieldId: 'filters',
					fieldCaption: 'Community Reviews Filter',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: COMMUNITY_REVIEW_OPTIONS,
					endpointId: GET_PENDING_DELIVERIES,
				},
				{
					fieldId: sectionKey,
					fieldCaption: 'Game',
					fixedLargeWidth: true,
					inputType: 'dropdownEmbedded',
					options: (state) => {
						const games = pipe(
							pluck('games'),
							values,
							map(head),
							uniqBy(prop('id')),
						)(state?.api?.records?.project || [])

						return [
							{
								label: 'All',
							},
							...map(game => ({
								label: game.name,
								payload: {
									gameId: game.id,
								},
							}), games),
						]
					},
					endpointId: GET_PENDING_DELIVERIES,
				},
				{
					fieldId: firstTimeDropperKey,
					fieldCaption: 'First time Dropper',
					inputType: 'checkboxEmbedded',
					endpointId: GET_PENDING_DELIVERIES,
				},
			],
		},
	},
}
