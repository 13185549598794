import pipe from 'ramda/src/pipe'

import { API_RECORD_REQUEST_SUCCESS } from 'root/src/client/logic/api/actionIds'
import {
	apiStoreLenses, idProp, mnemonicIdProp, projectUrlProp, requestedRecordIdProp,
} from 'root/src/client/logic/api/lenses'
import getLensFromType from 'root/src/client/logic/api/util/getLensFromType'
import currentRouteParamsRecordId from 'root/src/client/logic/route/selectors/currentRouteParamsRecordId'
import recordIdSelector from 'root/src/client/logic/api/selectors/recordIdSelector'
import { allKey } from 'root/src/shared/constants/keys'

const { setRecordProcessingChild } = apiStoreLenses

export const apiRecordRequestSuccess = (
	state,
	{ recordType, record, recordId },
) => {
	const recordIdMod = recordId
	|| requestedRecordIdProp(record)
	|| projectUrlProp(record)
	|| currentRouteParamsRecordId(state)
	|| mnemonicIdProp(record)
	|| idProp(record)
	|| recordIdSelector(state)
	|| allKey

	const setRecordsChild = getLensFromType('set', 'child', recordType, apiStoreLenses)

	return pipe(
		setRecordProcessingChild(recordIdMod, false),
		setRecordsChild(recordIdMod, record),
	)(state)
}

export default {
	[API_RECORD_REQUEST_SUCCESS]: apiRecordRequestSuccess,
}
