export var Language;
(function (Language) {
    Language["Undefined"] = "";
    Language["Null"] = "null";
    Language["English"] = "en";
    Language["AmericanSignLanguage"] = "asl";
    Language["Other"] = "other";
    Language["Norwegian"] = "no";
    Language["ChineseHongKong"] = "zh-hk";
    Language["Danish"] = "da";
    Language["German"] = "de";
    Language["Dutch"] = "nl";
    Language["Swedish"] = "sv";
    Language["Finnish"] = "fi";
    Language["French"] = "fr";
    Language["Japanese"] = "ja";
    Language["Korean"] = "ko";
    Language["Italian"] = "it";
    Language["Catalan"] = "ca";
    Language["Czech"] = "cs";
    Language["Slovak"] = "sk";
    Language["Turkish"] = "tr";
    Language["Polish"] = "pl";
    Language["Russian"] = "ru";
    Language["Hungarian"] = "hu";
    Language["Romanian"] = "ro";
    Language["Greek"] = "el";
    Language["Bulgarian"] = "bg";
    Language["Thai"] = "th";
    Language["Portuguese"] = "pt";
    Language["Chinese"] = "zh";
    Language["Spanish"] = "es";
    Language["Ukrainian"] = "uk";
    Language["Arabic"] = "ar";
    Language["Indonesian"] = "id";
    Language["Tagalog"] = "tl";
    Language["Vietnamese"] = "vi";
    Language["Hindi"] = "hi";
    Language["Malay"] = "ms";
})(Language || (Language = {}));
export const languageEmojis = {
    [Language.English]: '🇬🇧',
    [Language.Norwegian]: '🇧🇻',
    [Language.Danish]: '🇩🇰',
    [Language.German]: '🇩🇪',
    [Language.Dutch]: '🇳🇱',
    [Language.Swedish]: '🇸🇪',
    [Language.Finnish]: '🇫🇮',
    [Language.French]: '🇫🇷',
    [Language.Japanese]: '🇯🇵',
    [Language.Korean]: '🇰🇷',
    [Language.Italian]: '🇮🇹',
    [Language.Catalan]: '🏴󠁥󠁳󠁣',
    [Language.Czech]: '🇨🇿',
    [Language.Slovak]: '🇸🇰',
    [Language.Turkish]: '🇹🇷',
    [Language.Polish]: '🇵🇱',
    [Language.Russian]: '🇷🇺',
    [Language.Hungarian]: '🇭🇺',
    [Language.Romanian]: '🇲🇩',
    [Language.Greek]: '🇬🇷',
    [Language.Bulgarian]: '🇧🇬',
    [Language.Thai]: '🇹🇭',
    [Language.Portuguese]: '🇵🇹',
    [Language.Chinese]: '🇨🇳',
    [Language.Spanish]: '🇪🇸',
    [Language.Ukrainian]: '🇺🇦',
    [Language.Arabic]: '🇦🇪',
    [Language.Indonesian]: '🇮🇩',
    [Language.Vietnamese]: '🇻🇳',
    [Language.Hindi]: '🇮🇳',
    [Language.Malay]: '🇲🇾',
};
export const readableLanguages = {
    [Language.English]: 'English',
    [Language.Norwegian]: 'Norwegian',
    [Language.Danish]: 'Danish',
    [Language.German]: 'German',
    [Language.Dutch]: 'Dutch',
    [Language.Swedish]: 'Swedish',
    [Language.Finnish]: 'Finnish',
    [Language.French]: 'French',
    [Language.Japanese]: 'Japanese',
    [Language.Korean]: 'Korean',
    [Language.Italian]: 'Italian',
    [Language.Catalan]: 'Catalan',
    [Language.Czech]: 'Czech',
    [Language.Slovak]: 'Slovak',
    [Language.Turkish]: 'Turkish',
    [Language.Polish]: 'Polish',
    [Language.Russian]: 'Russian',
    [Language.Hungarian]: 'Hungarian',
    [Language.Romanian]: 'Romanian',
    [Language.Greek]: 'Greek',
    [Language.Bulgarian]: 'Bulgarian',
    [Language.Thai]: 'Thai',
    [Language.Portuguese]: 'Portuguese',
    [Language.Chinese]: 'Chinese',
    [Language.Spanish]: 'Spanish',
    [Language.Ukrainian]: 'Ukrainian',
    [Language.Arabic]: 'Arabic',
    [Language.Indonesian]: 'Indonesian',
    [Language.Vietnamese]: 'Vietnamese',
    [Language.Hindi]: 'Hindi',
    [Language.Malay]: 'Malay',
};
