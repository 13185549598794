import { HOMEPAGE_CREATORS_SECTION } from 'root/src/shared/descriptions/modules/moduleIds'
import { CREATORS_PAGE_ROUTE_ID } from 'root/src/shared/descriptions/routes/routeIds'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import Home3Blob from 'root/src/client/public/backgroundBlobs/home-3.svg'
import {
	dropDareDifficultyLevelEnum,
	brandProductTypesEnum,
} from 'root/src/shared/constants/dropEvent'
import { ltGtMediaQuery, ltMdMediaQuery } from 'root/src/client/web/commonStyles'
import ddLogo from 'root/src/client/public/favicons/android-chrome-192x192.jpg'

export default {
	[HOMEPAGE_CREATORS_SECTION]: {
		moduleType: 'static',
		staticPageType: 'section',
		backgroundData: [
			{
				position: 'right',
				image: Home3Blob,
				style: {
					transform: 'translate(0%, -40%)',
					[ltMdMediaQuery]: {
						transform: 'translate(0%, -18%)',
					},
					[ltGtMediaQuery]: {
						transform: 'translate(0%, -12%)',
					},
				},
			},
		],
		pageContent: {
			projectCard: {
				brand: {
					brandName: 'Dare Drop',
					brandLogo: {
						small: ddLogo.src,
					},
				},
				dare: {
					capacity: {
						available: 23,
						used: 17,
					},
					title: 'Nade Parade',
					description: 'Only use grenades in the final',
					games: [{ image: 'https://static-cdn.jtvnw.net/ttv-boxart/511224-280x320.jpg' }],
					level: dropDareDifficultyLevelEnum.easy,
					product: { name: '10 Keys', type: brandProductTypesEnum.dropKey },
				},
				dumb: true,
			},
			heading: 'Creators—',
			subHeading: 'Do you dare?',
			text: 'Earn rewards for your fans like keys, swag, and in-game content when you complete dares.',
			position: 'left',
			buttons: [
				{
					label: 'For Creators',
					routeId: CREATORS_PAGE_ROUTE_ID,
					icon: faChevronRight,
				},
			],
		},
	},
}
