var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { twMerge } from 'tailwind-merge';
import { forwardRef, memo } from 'react';
import { Slot } from '@radix-ui/react-slot';
export var ButtonNewVariant;
(function (ButtonNewVariant) {
    ButtonNewVariant["Primary"] = "primary";
    ButtonNewVariant["Secondary"] = "secondary";
    ButtonNewVariant["Outline"] = "outline";
    ButtonNewVariant["PrimaryNoHover"] = "primaryNoHover";
    ButtonNewVariant["Error"] = "error";
    ButtonNewVariant["Ghost"] = "ghost";
    ButtonNewVariant["Success"] = "success";
})(ButtonNewVariant || (ButtonNewVariant = {}));
const variants = {
    [ButtonNewVariant.Primary]: 'bg-primary border-secondary hover:border-primary hover:bg-secondary motion-safe:hover:scale-[102%] motion-safe:active:scale-[98%]',
    [ButtonNewVariant.PrimaryNoHover]: 'bg-primary border-secondary motion-safe:hover:scale-[102%] motion-safe:active:scale-[98%]',
    [ButtonNewVariant.Secondary]: 'bg-secondary border-secondary hover:border-primary hover:bg-secondary',
    [ButtonNewVariant.Outline]: 'bg-transparent border-secondary hover:border-primary hover:bg-secondary',
    [ButtonNewVariant.Error]: 'bg-pink border-transparent bg-pink hover:border-transparent font-bold hover:bg-pink',
    [ButtonNewVariant.Ghost]: 'bg-transparent border-none hover:border-none hover:bg-secondary',
    [ButtonNewVariant.Success]: 'bg-teal text-dark-2 border-teal-shadow hover:bg-teal-shadow hover:border-teal',
};
export const ButtonNew = memo(forwardRef((_a, ref) => {
    var { children, className, disabled, variant = ButtonNewVariant.Primary, asChild } = _a, props = __rest(_a, ["children", "className", "disabled", "variant", "asChild"]);
    const variantClasses = variants[variant];
    const Comp = asChild ? Slot : 'button';
    return (<Comp ref={ref} disabled={disabled} type="submit" className={twMerge('group bg-primary relative rounded-lg px-5 py-1.5 text-md shadow tracking-wide border-[3px] border-secondary motion-safe:duration-150 font-medium', disabled && 'opacity-50 cursor-not-allowed motion-safe:hover:scale-100 motion-safe:active:scale-100', variantClasses, className)} {...props}>
			{children}
		</Comp>);
}));
