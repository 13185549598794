export var DashboardOrderSortTypes;
(function (DashboardOrderSortTypes) {
    DashboardOrderSortTypes["ASC"] = "ASC";
    DashboardOrderSortTypes["DESC"] = "DESC";
})(DashboardOrderSortTypes || (DashboardOrderSortTypes = {}));
export var CreatorsTableTab;
(function (CreatorsTableTab) {
    CreatorsTableTab["Participating"] = "participating";
    CreatorsTableTab["Waitlist"] = "waitlist";
})(CreatorsTableTab || (CreatorsTableTab = {}));
