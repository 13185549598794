export default {
	type: 'object',
	properties: {
		projectUrl: { type: 'string' },
		approveDelivery: { type: 'boolean' },
		messageToCreator: { type: 'string' },
	},
	required: ['projectUrl'],
	additionalProperties: false,
}
